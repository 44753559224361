import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import '@progress/kendo-theme-default/dist/all.css';
import { HashRouter, BrowserRouter as Router } from 'react-router-dom';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);


