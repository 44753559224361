export const ClientRoutes = {
    Logout: '/logout',
    Login: '/login',
    LoginCallback: '/login-callback',
    Home: '/home',
    Explorer: '/home/explorer',
    System: '/home/system',
    HealthMetrics: '/home/health-metrics',
    
}


export const ReactQueryKeys = {
    AllAccountsQuery: "accounts",
    AllEndpointsStatusQuery: "endpointsStatus",
    AllSelectedEndpoinstStatusQuery: "selectedEndpointsStatus",
    AllExplorerFiltersQuery: "explorerFilters",
    AllExplorerAccountsHealthMetricsQuery: "explorerAccountsHealthMetrics",
    AllExplorerFilterHealthMetricsQuery: "explorerFilterHealthMetrics",
    AllHealthMetrics: "healthMetrics",
    AllAccountSettingsQuery: "accountSettings",
}