import { useLocalization } from "@progress/kendo-react-intl";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { useState } from "react";
import { AlertJobsPage } from "./AlertJobsPage";
import ComplianceReportJobsPage from "./ComplianceReportJobsPage";


export const SystemPage = () => {
  const localizationService = useLocalization();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

    return (
      <div>
        <div className='gb-h2 py-2 pl-5 bg-white'>System</div>
        <div className="p-4">
        <TabStrip selected={selected} onSelect={handleSelect} className="system-page w-full">
          <TabStripTab title="Alert Jobs">
            <AlertJobsPage/>
          </TabStripTab>
          <TabStripTab title="Compliance Report Jobs" >
            <ComplianceReportJobsPage/>
          </TabStripTab>
        </TabStrip>
        </div>
      </div>
    );
}


