import {
  GridColumnMenuSort,
  GridColumnMenuProps,
  GridColumnMenuCheckboxFilter,
} from "@progress/kendo-react-grid";


interface CustomColumnMenuProps extends GridColumnMenuProps {
  data: Array<any>;
}

export const CustomColumnMenuFilter = (props: CustomColumnMenuProps) => {

  return (
    <div>
      <GridColumnMenuSort {...props} />
        <GridColumnMenuCheckboxFilter
        {...props}
        data={props.data}
        expanded={true}
        searchBoxFilterOperator={"contains"}
      />
    </div>
  );
};