import * as React from 'react';
import {Drawer, DrawerContent, DrawerSelectEvent,} from "@progress/kendo-react-layout";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import * as svgIcons from '@progress/kendo-svg-icons';

const items = [
  { text: "Explorer", svgIcon: svgIcons.zoomInIcon, route: "/home/explorer",  },
  // { separator: true },
  { text: "Health Metrics", svgIcon: svgIcons.tableIcon, route: "/home/health-metrics", },
  { text: "System", svgIcon: svgIcons.wrenchIcon, route: "/home/system", },
  

];

export const DrawerContainer = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = React.useState(true);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const onSelect = (e: DrawerSelectEvent) => {
    navigate(e.itemTarget.props.route);
  };

  const setSelectedItem = (pathName: any) => {
    let currentPath: any = items.find((item) => item.route === pathName);
    if (currentPath.text) {
      return currentPath.text;
    }
  };

  const selected = setSelectedItem(location.pathname);

    return (
      <div>
        <div className="custom-toolbar">
          <Header 
            onButtonClick={handleClick}
          />
        </div>
        <Drawer
          className='sidebar'
          style={{background: "#E6E7E9", paddingTop:'60px', height:'100vh'}}
          expanded={!expanded}
          position={'start'}
          mode="push"
          mini={true}
          items={items.map((item) => ({
            ...item,
            selected: item.text === selected,
          }))}
          onSelect={onSelect}
        >
          <DrawerContent >{props.children}<Outlet/></DrawerContent>
        </Drawer>
        <Footer/>
      </div>
    );
}