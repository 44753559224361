import { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

interface Props {
  token: string;
  selectedItemsIds: any
  type: any
}

export const OverviewSelectedDashboard = (props:Props) => {

  const dashboardDiv = useRef<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rendered, setRendered] = useState(false);
  const sdk = new ChartsEmbedSDK({})
  const dashboardId = props.type === "Account" ? process.env.REACT_APP_MONGO_DB_SELECTED_DASHBOARD_ID_SITE :  props.type === "Location" ? process.env.REACT_APP_MONGO_DB_SELECTED_DASHBOARD_ID_AREA : process.env.REACT_APP_MONGO_DB_SELECTED_DASHBOARD_ID_ASSET
  const [dashboard, setDashboard] = useState(sdk.createDashboard({
    baseUrl: process.env.REACT_APP_MONGO_DB_URL,
    dashboardId: dashboardId,
    height: "675px",
    widthMode: "scale",
    heightMode: "fixed",
    getUserToken: () => props.token,
    autoRefresh: true,
    maxDataAge: 3600,
  }) )


  const displayLoading = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);

  };

  useEffect(() => {
    if (dashboardDiv.current) {
      dashboard.render(dashboardDiv.current).then(() => {setRendered(true)});
    }
  }, [props.token, dashboard]);

  useEffect(() => {
    displayLoading();

    if (rendered) {  
      dashboard.setFilter(props.selectedItemsIds).catch(err => console.log("Error while filtering.", err));
    }

  }, [dashboard, rendered, props.selectedItemsIds]);

  useEffect(() => {
  
    setDashboard(sdk.createDashboard({
      baseUrl: process.env.REACT_APP_MONGO_DB_URL,
      dashboardId: dashboardId,
      height: "675px",
      widthMode: "scale",
      heightMode: "fixed",
      getUserToken: () => props.token,
      autoRefresh: true,
      maxDataAge: 3600,
      filter: props.selectedItemsIds
    }));

  }, [props.token, dashboardId]);


  return (
    <div>
      {isLoading && (<LoadingSpinner/>)}
      <div ref={dashboardDiv}/>
    </div>
  );
};

