import { useEffect, useState } from "react";
import authorizeService from "../../identity/utilities/AuthorizeService";
import LoadingSpinner from "../../shared/components/LoadingSpinner";


export default function ComplianceReportJobsPage() {
  const urlBase = process.env.REACT_APP_HANGFIRE_COMPLIANCE_REPORT_URL

  const [token, setToken] = useState(''); 

  useEffect(() => {
    async function getToken() {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    }

    getToken();
  }, []);

  if (!token) {
    return <LoadingSpinner />;
  } else
  {
  return (
    <div className=''>
      <iframe title="ComplianceReportJobs" src={`${urlBase}?jwt=${token}`} width={"100%"} height={750} ></iframe>
    </div>
    );
}}
