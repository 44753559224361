import { Grid, GridCellProps, GridColumn, GridColumnProps, GridDataStateChangeEvent, GridHandle, GridToolbar } from "@progress/kendo-react-grid";
import { DataResult, SortDescriptor, State, process } from "@progress/kendo-data-query";
import { useCallback, useState } from "react";
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ColumnMenuContext } from "../utilities/ColumnMenuContext";
import React from "react";
import { ColumnMenu } from "../utilities/ColumnMenu";
import { Button } from "@progress/kendo-react-buttons";
import { CustomColumnMenuFilter } from "../../shared/utilities/customColumnMenuFilter";


interface Props {
  accountsHealthMetrics: any
}

export const HealthMetricsTable = (props: Props) => {

  const accountsHealthMetrics = props.accountsHealthMetrics

  const allAccounts = accountsHealthMetrics.accounts
  const { accounts, ...metrics } = accountsHealthMetrics;

  const columns = allAccounts.map((item: any) => ({
    title: item,
    field: item,
    id: item,
    columnMenu: ColumnMenu
  }));

  const transformData = (data:any) => {
    let transposeData = [];
    for (let metric in data) {
      let itemArray:any = data[metric];
      let newItem:any = {
        metric
      };

      for (let i = 0; i < itemArray.length; i++) {
        newItem[i] = itemArray[i];
      }
      transposeData.push(newItem);
    }
    
    return transposeData;
  };

  let parsedData = transformData(metrics);

  const accountsCell = (props: GridCellProps) => {
    //const index = props.columnIndex !== undefined ? props.columnIndex : 0;
    const accountIndex = accounts.indexOf(props.field);
    const data = props.dataItem[accountIndex]
    const metric = props.dataItem["metric"];
  
    const redColour = "#ff5034";
    const greenColour = "#1ab394";
    const orangeColour = "#ff8c00";
    const blueColour = "#3CB3FF";
  
    if(metric === 'Users Registered (%)' ) {
      const trafficLight = data === 1 ? greenColour : redColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{data < 0 ? '-' : ((data * 100).toFixed(0) +'%') }</span>
        </td>
      );
    } else if (metric === 'Users Logged In Last 60 Days') {
      const trafficLight = data > 0 ? greenColour : redColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{data}</span>
        </td>
      );
  
    } else if(metric === 'Reservoirs At Negative Level') {
        const trafficLight = data === 0 ? greenColour : redColour
        return (
          <td>
            <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
            <span>{data}</span>
          </td>
        );
    } else if(metric === 'Reservoir Alerts Triggered Last 30 Days' || metric === 'Reservoir Critical Alerts Triggered Last 30 Days' || metric === 'EP Alerts Triggered Last 30 Days') {
      const trafficLight =data > 1 ? redColour :data === 1 ? orangeColour : greenColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{data}</span>
        </td>
      );
    } else if(metric === 'Manual Compliance Last 30 Days (%)' || metric === 'EP Compliance Last 30 Days (%)') {
      const trafficLight = data >= 0.9 ? greenColour : data < 0.9 && data >= 0.7 ? orangeColour : redColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{data < 0 ? '-' : ((data * 100).toFixed(0) +'%') }</span>
        </td>
      );
  
    } 
    else if( metric === 'Head Unit Last Cloud Contact within 24h' ) {
      const trafficLight = data[0] < data[1] ? redColour : greenColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{ data[0] + "/" + data[1]}</span>
        </td>
      );
    } 
    else if( metric === 'Head Unit Last Cloud Contact within 6h' ) {
      const trafficLight = data[0] < data[1] ? redColour : greenColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{ data[0] + "/" + data[1]}</span>
        </td>
      );
    }
    else if( metric === 'Gateway Last Cloud Contact within 2h' || metric === 'Gateway Last Cloud Contact within 6h' || metric === 'Gateway Last Cloud Contact within 24h') {
      const trafficLight = data[0] < data[1] ? redColour : greenColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{ data[0] + "/" + data[1]}</span>
        </td>
      );
    }  
    else if(metric === '# GW outdoor/lubeshed ordered' || metric === '# HU colour ordered' || metric === '# Tags R/Y/Butt ordered' || metric === '# Endpoint HF/LF/Bulk as ordered') {
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: "transparent"}}/>
          <span>{data < 0 ? '-' : data }</span>
        </td>
      );
    }
    else if(metric === '# EP Unmapped (NO Serial No.)' || metric === '# EP Mapped (NO Rqmnt. + NO Alert)' || metric === '# EP Mapped + Rqmnt. (NO Alert)') {
      const trafficLight = data === 0 ? greenColour : redColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{data}</span>
        </td>
      );
    }
    else if (metric === 'Tag Map item with NO RFID' || metric === 'Tag Map item with rfid NO SCHEDULE') {
      const trafficLight = data === 0 ? greenColour : redColour
      return (
        <td>
          <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
          <span>{data}</span>
        </td>
      );
    }
    
    return (
      <td>
        <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: blueColour}}/>
        {data}
      </td>
    );
    };

  const initialSort: Array<SortDescriptor> = [ { field: "ProductID", dir: "asc" },];
  const [sort] = useState(initialSort);

  const createDataState = (dataState: State) => {
    return {
      result: process(parsedData.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 30,
    skip: 0,
    sort: sort,
  });

  const [result, setResult] = useState<DataResult>(initialState.result);
  const [dataState, setDataState] = useState<State>(initialState.dataState);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const [columnsState, setColumnsState] = useState(columns);

  const onColumnsChange = useCallback((cols: GridColumnProps[]) => {
    setColumnsState(cols);
  }, []);

  const gridRef = React.useRef<GridHandle>(null);

  const _exporter = React.createRef<ExcelExport>();
  const excelExport = () => {
    save(_exporter);
  };
  const save = (component: any) => {
    const options = component.current.workbookOptions();
    const rows = options.sheets![0].rows;

    for(var row=1; row < rows.length; row++){
      var metric
      for(var col=0; col < rows[row].cells.length ; col++) {
        if(col !== 0) {
          const accountIndex = accounts.indexOf(columnsState[col - 1].field);
          if(metric === 'Head Unit Last Cloud Contact within 24h' || metric === 'Head Unit Last Cloud Contact within 6h' || 
            metric === 'Gateway Last Cloud Contact within 2h' || metric === 'Gateway Last Cloud Contact within 6h' || metric === 'Gateway Last Cloud Contact within 24h'){
            rows[row].cells[col].value = component.current.props.data[row -1][accountIndex][0] + '/' + component.current.props.data[row -1][accountIndex][1]
          } else {
            rows[row].cells[col].value = component.current.props.data[row -1][accountIndex]
          }
        } else {
          metric = rows[row].cells[col].value
        }
      }
    }

    component.current.save(options);
  };

   return (
    <div>
      <ColumnMenuContext.Provider
        value={{ onColumnsChange, columnsState, columns }}
      >
        <ExcelExport ref={_exporter} data={parsedData}>
        <Grid
          data={result}
          {...dataState}
          onDataStateChange={dataStateChange}
          pageSize={8}
          ref={gridRef}
          sortable={true}
          style={{maxHeight: 'calc(100vh - 130px)'}}
        >
            <GridToolbar >
              <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
                <div className="gb-h3 pl-1">Health Metrics</div>
                <Button
                  svgIcon={svgIcons.downloadIcon}
                  onClick={excelExport}
                >
                </Button>
              </div>
            </GridToolbar>
            <GridColumn
              field="metric"
              title="Metric"
              width="300px"
              locked={true}
              columnMenu={(props) => <CustomColumnMenuFilter {...props} data={parsedData} />}
            />          
          {columnsState.map((c: any) => (
            <GridColumn key={c.id} {...c} width="200px" cell={accountsCell}/>
          ))}
          
        </Grid>
        </ExcelExport>
      </ColumnMenuContext.Provider>
    </div>
   )
};
