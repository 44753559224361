import React, { ReactElement } from "react";
import LoadingSpinner from "../components/LoadingSpinner";

interface Props {}

export default function LoadingPage(props: Props): ReactElement {
  return (
    <div className="h-screen w-screen flex justify-center align-middle">
        <LoadingSpinner />
    </div>
  );
}
