// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.

import React, { ReactElement, useEffect, useState } from "react";
import ErrorPage from "../../shared/pages/ErrorPage";
import LoadingPage from "../../shared/pages/LoadingPage";
import authService, { AuthenticationResultStatus } from "../utilities/AuthorizeService";
import { LoginActions } from "../../../constants/IdentityConstants";
import ErrorCard from "../../shared/components/ErrorCard";
import { useLocalization } from '@progress/kendo-react-intl';

interface Props {
  action: string;
}

/**
 * This component is responsible for dealing
 */
export default function LoginHandler({ action }: Props): ReactElement {
  const [message, setMessage] = useState<string>("");
  const localizationService = useLocalization();
  //On component mount, get the action from the prop, depending on the action we determine process
  useEffect(() => {
    switch (action) {
      case LoginActions.Login:
        login(authService.getReturnUrl());
        break;
      case LoginActions.LoginCallback:
        processLoginCallback();
        break;
      case LoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get("message");
        setMessage(error ?? "");
        break;
      default:
        throw new Error(`Invalid action.`);
    }
  }, []);

  const login = async (returnUrl: string) => {
    const state = { returnUrl };
    const result = await authService.signIn(state);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(result.message.toString());
        break;
      default:
        throw new Error(`Invalid status result.`);
    }
  };

  //Invoked when the user has returned from a login on the token server, based on the result
  const processLoginCallback = async () => {
    const url = window.location.href;
    const result = await authService.completeSignIn(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeSignIn finishes
        // is when we are doing a redirect sign in flow.
        throw new Error(`Should not redirect.`);
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(authService.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        setMessage(message);
        break;
      default:
        throw new Error(`Invalid authentication result status '${result.status}'.`);
    }
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    // It's important that we do a replace here so that we remove the callback uri with the
    // fragment containing the tokens from the browser history.
    window.location.replace(returnUrl);
  };

  //RENDER OUTPUT
  if (!!message) {
    return (
      <div className="m-4">
        <ErrorCard />
      </div>
    );
  } else {
    switch (action) {
      case LoginActions.Login:
        return <LoadingPage />;
      case LoginActions.LoginCallback:
        return <LoadingPage />;
      default:
        return <ErrorPage />;
    }
  }
}
