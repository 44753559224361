
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../identity/utilities/AuthorizeService";
import { ApiRoutes } from "../../../constants/ApiRoutingConstants";
import { EndpointStatus } from "../../../models/endpoints/EndpointSatus";
import { AllAccounts } from "../../../models/accounts/AllAcounts";
import { AccountHealth } from "../../../models/accounts/AccountHealth";
import { AccountSettings } from "../../../models/accounts/AccountSettings";

/**
 * This service provides implementations for http calls to the api pertaining to crud functionality
 */

class AccountsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }

  async getAllAccounts(): Promise<AllAccounts[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.Accounts}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getSelectedEndpointsStatus(selectedItems: { id: string; siteId: string | undefined; type: string | undefined, parentId: string | undefined }[], reportByException: boolean): Promise<EndpointStatus[]> {
    const endpointStatusFilters = {
      selectedItems: JSON.stringify(selectedItems),
      lastFlowEventHourOffset: '72',
      lastDiagnosticEventHourOffset: '12',
      lastHeartbeatEventHourOffset: '12',
      reportByException: reportByException.toString()
   };

    let response = await this.axiosInstance({
      
      method: "GET",
      url: `${ApiRoutes.EndpointStatus}?${new URLSearchParams(endpointStatusFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getFilterHealthMetrics(selectedItems: any): Promise<any> {
    const healthFilters = {
      selectedItems: JSON.stringify(selectedItems)}

    let response = await this.axiosInstance({
      
      method: "GET",
      url: `${ApiRoutes.ExplorerAccountHealth}/custom?${new URLSearchParams(healthFilters).toString()}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAllFiltersFlat(): Promise<AllAccounts[]> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ExplorerFilters}/tree`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getFilter(filterId: string | null) {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ExplorerFilters}/${filterId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async createFilter(filter: any) {
    let response = await this.axiosInstance({
      method: "POST",
      url: `${ApiRoutes.ExplorerFilters}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: filter,
    });
    return response.data;
  }

  async updateFilter(filteredData:any, filterId: string) {
    let response = await this.axiosInstance({
      method: "PUT",
      url: `${ApiRoutes.ExplorerFilters}/${filterId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
      data: filteredData,
    });
    return response.data;
  }

  async deleteFilter(filterId: string) {
    let response = await this.axiosInstance({
      method: "DELETE",
      url: `${ApiRoutes.ExplorerFilters}/${filterId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      }
    });
    return response.data;
  }

  async getHealthMetrics(siteId: string): Promise<AccountHealth> {

    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ExplorerAccountHealth}/${siteId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  async getAccountSettings(siteId: string): Promise<AccountSettings> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.AccountSettings}/${siteId}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  static get instance() {
    return AccountsApiService;
  }

}

const accountsApiService = new AccountsApiService();
export default accountsApiService;
