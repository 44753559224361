import * as React from "react";
import {
  GridColumnMenuProps,
  GridColumnMenuColumnsList,
} from "@progress/kendo-react-grid";
import { ColumnMenuContext } from "./ColumnMenuContext";

export const ColumnMenu: React.FunctionComponent<GridColumnMenuProps> = (
  props
) => {
  const {  onColumnsChange, columnsState, columns } =
    React.useContext(ColumnMenuContext);
  const { onCloseMenu = () => {} } = props;


  return (

        <GridColumnMenuColumnsList
          columnsState={columnsState}
          columns={columns}
          onColumnsChange={onColumnsChange}
          onCloseMenu={onCloseMenu}
        />

  );
};