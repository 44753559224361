import { ReactElement } from "react";
import GenericConfirmationDialog from "./GenericConfirmationDialog";
import { useLocalization } from '@progress/kendo-react-intl';
import { enMessages } from "../../../messages/en-US";


interface Props {
  title: string
  deletingItemLabel: string | undefined;
  itemCount: number;
  open: boolean;
  onClose: () => void;
  deleteCallback: () => void;
  children: React.ReactNode;
}

export default function DeleteDialog({
  title,
  deletingItemLabel,
  open,
  onClose,
  deleteCallback,
}: Props): ReactElement {
  const localizationService = useLocalization();
  return (
    <GenericConfirmationDialog
      open={open}
      cancel={"Cancel"}
      confirm={"Delete"}
      onClose={onClose}
      body={`Are you sure you want to delete ${deletingItemLabel}?`}
      header={title}
      onConfirm={deleteCallback}
      confirmButtonTheme={"primary"}
    />
  );
}
