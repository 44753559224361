import { useEffect, useMemo, useState } from "react";
import authorizeService from "../../../identity/utilities/AuthorizeService";
import { OverviewSelectedDashboard } from "../../components/Overview/OverviewSelectedDashboard";

interface Props {
  selectedItem: { id: string; siteId: string | undefined; type: string | undefined, parentId: string | undefined }[];
  selectedFilter: any;
}

export const OverviewSelectedPage = (props: Props) => {

const [token, setToken] = useState<string>(''); 

useEffect(() => {
  async function getToken() {
    const user = await authorizeService.getUser();
    setToken(user.access_token);
  }

  getToken();
}, []);

const selectedIds = useMemo(() => {
  if(props.selectedItem.every(item => item.type === "Filter")) {
    const selectedItemsFilterIds = props.selectedFilter.map((item: { id: any; }) => item.id);
    return [{ "SiteId": { "$in": selectedItemsFilterIds } }, {type: "Account"}];
  }
  else if (props.selectedItem.every(item => item.type === "Account")) {
    const selectedItemsSiteIds = props.selectedItem.map(item => item.siteId);
    return [{ "SiteId": { "$in": selectedItemsSiteIds } }, {type: "Account"}];
  } else if (props.selectedItem.every(item => item.type === "Location")) {
    const selectedItemsLocationIds = props.selectedItem.map(item => item.id);
    return [{ "AreaId": { "$in": selectedItemsLocationIds }}, {type: "Location" }];
  } else if (props.selectedItem.every(item => item.type === "Asset")) {
    const selectedItemsAssetIds = props.selectedItem.map(item => item.id);
    return [{ "AssetId": { "$in": selectedItemsAssetIds } }, {type: "Asset"}];
  } else {
    return []
  }
}, [props.selectedItem]);

return (
  <div>
    {selectedIds.length === 0 ? 
    <div>No Data Available for different types.</div> 
    : 
    <OverviewSelectedDashboard token={token} selectedItemsIds={selectedIds[0]} type={selectedIds[1].type}/>
}
  </div>
);
}