
import axios, { AxiosInstance } from "axios";
import authorizeService from "../../identity/utilities/AuthorizeService";
import { ApiRoutes } from "../../../constants/ApiRoutingConstants";
import { AllAccountsHealth } from "../../../models/accounts/AllAccountsHealth";

/**
 * This service provides implementations for http calls to the api pertaining to crud functionality
 */

class HealthMetricsApiService {
  axiosInstance: AxiosInstance;
  constructor() {
    //Subscribe to identity store the get token
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_ASSET_SERVICE_URL,
    });
  }


  async getAllHealthMetrics(): Promise<AllAccountsHealth> {
    let response = await this.axiosInstance({
      method: "GET",
      url: `${ApiRoutes.ExplorerAccountHealth}`,
      headers: {
        Authorization: `Bearer ${(await authorizeService.getUser()).access_token}`,
      },
    });
    return response.data;
  }

  static get instance() {
    return HealthMetricsApiService;
  }

}

const healthMetricsApiService = new HealthMetricsApiService();
export default healthMetricsApiService;
