import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import accountsApiService from "../utilities/ExplorerApiService";
import { ReactQueryKeys } from "../../../constants/ClientRoutingConstants";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorCard from "../../shared/components/ErrorCard";
import { ExplorerTabsPage } from "./ExplorerTabsPage";
import { FilterList } from "../components/FilterList/FilterList";
import { TreeListComponent } from "../components/TreeListComponent";

interface SelectedStateItem {
  [id: string]: number[] | boolean;
}

interface FilterItem {
  id: string;
  parentId: string;
}

export const ExplorerPage = () => {
  const filtersFlatQuery = useQuery<any>(ReactQueryKeys.AllExplorerFiltersQuery, async () => {
    return accountsApiService.getAllFiltersFlat();
  });

  const [selectedState, setSelectedState] = useState<SelectedStateItem>({});
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [isAddFilter, setIsAddFilter] = useState<boolean>(false);
  const [filterToUpdate, setFilterToUpdate] = useState<any | null>(null);
  const [filterToDelete, setFilterToDelete] = useState<any | null>(null);

  const filtersFlatDataNoUnderscore = ()  => {
     // Extract the IDs without underscores
     const filtersFlatData = filtersFlatQuery.data?.map((obj: FilterItem) => {
      obj.id = obj.id.includes('_') ? obj.id.split('_')[1] : obj.id;
      obj.parentId = obj.parentId.includes('_') ? obj.parentId.split('_')[1] : obj.parentId;
      return obj;
    });
    return filtersFlatData!;
  };
  
  
  useEffect(() => {
    // Extract the IDs without underscores
    const filtersFlatData = filtersFlatDataNoUnderscore();

    const selectedStateIds: { [id: string]: number[] | boolean } = {};
    for (const key in selectedState) {
      const id = key.includes('_') ? key.split('_')[1] : key;
      selectedStateIds[id] = selectedState[key];
    }

    const idsTrue = Object.keys(selectedStateIds).filter((key) => selectedStateIds[key] === true).map((key) => ({ id: key }));

    if (idsTrue.length !== 0) {
      const idsWithType = idsTrue.map((idObject) => {
        const matchingDataItem = filtersFlatData.find((item: { id: string; }) => item.id === idObject.id);
        return { ...idObject, type: matchingDataItem?.type, siteId: matchingDataItem?.siteId, parentId: matchingDataItem?.parentId, name: matchingDataItem?.name };
      });
      setSelectedItems(idsWithType);
    } else {
      setSelectedItems([]);
    }
  }, [selectedState]);

  const selectedFilter = useMemo(() => {
     // Extract the IDs without underscores
     const filtersFlatData = filtersFlatDataNoUnderscore();

    const filteredData = filtersFlatData?.filter((dataItem: FilterItem) => {
      return selectedItems.some((item) => item.type === 'Filter' && dataItem.parentId === item.id);
    });

    return filteredData;
  }, [selectedItems]);

  useEffect(() => {
    filtersFlatQuery.refetch();
  }, [isAddFilter, filterToUpdate, filterToDelete]);

  const [panes, setPanes] = useState<any>([{ size: "20%", collapsible: true, keepMounted: true }, {scrollable: false}]);

  const onChangeSplitter = (event: SplitterOnChangeEvent) => {
    setPanes(event.newState);
  };

  if (filtersFlatQuery.isLoading || filtersFlatQuery.isFetching) {
    return <LoadingSpinner />;
  }

  if (filtersFlatQuery.error) {
    return <ErrorCard />;
  }

  return (
    <div className="">
      <div className="gb-h2 py-2 pl-5 bg-white">Explorer</div>
      <div className="p-4">
        <Splitter
          style={{ background: "#FAFAFA", height: "calc(100vh - 130px)", border: "none" }}
          panes={panes}
          onChange={onChangeSplitter}
        >
          <>
            <FilterList
              selectedItem={selectedItems}
              isAddFilter={isAddFilter}
              updateIsAddFilter={setIsAddFilter}
              filterToUpdate={filterToUpdate}
              setFilterToUpdate={setFilterToUpdate}
              filterToDelete={filterToDelete}
              setFilterToDelete={setFilterToDelete}
            />
            <TreeListComponent
              treeListData={filtersFlatQuery.data!}
              updateSelectedItem={setSelectedItems}
              updateSelectedState={setSelectedState}
              selectedState={selectedState}
            />
          </>
          <ExplorerTabsPage selectedItems={selectedItems} selectedFilter={selectedFilter} />
        </Splitter>
      </div>
    </div>
  );
};
