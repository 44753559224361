import { AppBar, AppBarSection, AppBarSpacer, Avatar } from '@progress/kendo-react-layout';
import * as React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from "../../../assets/logo-blue.png"
import MiniLogo from "../../../assets/logo-blue.png";
import useIdentityStore from '../../../zustand/identityStore';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { AppContext } from '../../../AppContext'
import { useLocalization } from '@progress/kendo-react-intl';
import { locales } from '../../../messages/locales';
import authService from '../../identity/utilities/AuthorizeService';
import { DropDownButton, SplitButton } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

const titleMenuItems = [
  {
    text: 'Logout', 
    onClick: () =>  authService.signOut(null)
  }
  ]

  const handleItemClick = (event: any) => {
    titleMenuItems.map(i => { return(
      i.text === event.item.text ? i.onClick() : null
    )})
  }
export const Header = (props: any) => {
  const { onButtonClick } = props;
  const userProfile = useIdentityStore((store) => store.user?.profile);
  const {localeId, onLanguageChange } = React.useContext<any>(AppContext);
  const localizationService = useLocalization();
  const currentLanguage = locales.find(item => item.localeId === localeId);

  const valueRender = (element: any,value: any) => {
    if (!value) {
      return element;
    }
    const children = [<span style={{textAlign:"right"}}>{value.abbreviation}</span>,];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <div className="">
      <AppBar themeColor="inherit"  className='justify-between' position='top' positionMode='fixed' style={{background: 'white', height:'60px'}}>
        <div className='flex items-center pl-2'>
          <AppBarSection>
            <div className="menu-button cursor-pointer">
              <SvgIcon icon={svgIcons.menuIcon} onClick={onButtonClick}/>
            </div>
          </AppBarSection>
          
          {/* Logo */}
          <AppBarSection className='mx-5'>
              <Link className=" md:block" to="/">
                <img src={LogoImage} alt="Grease Cloud Logo" className="px-2  h-12" />
              </Link>
            </AppBarSection>

            <AppBarSection>
              <div className="hidden md:block gb-h2 font-bold">Admin Portal</div>
            </AppBarSection>
        </div>
        <div >
          <AppBarSection className='mr-2' >
              <DropDownList
                key={currentLanguage?.localeId}
                textField={'locale'}
                dataItemKey={'localeId'}
                data={locales}
                value={currentLanguage}
                onChange={onLanguageChange}
                style={{background:"white", border:"0px", width:"54px"}}
                popupSettings={{width:"120px"}}
                valueRender={valueRender}
            />
          </AppBarSection>
          <AppBarSection className=" p-2 pl-3">
            <Avatar type="text" style={{background:'#323543', borderRadius: 0}}>{userProfile?.preferred_username?.match(/\b(\w)/g)?.join("")}</Avatar>
            <div className="flex flex-col ml-3 hidden md:block">
              <div className="font-sans" style={{color:'#323543'}}>{userProfile?.preferred_username}</div>
              <div className="text-xs text-gray-600 font-sans" style={{color:'#323543'}}>{userProfile?.role.replace(/[A-Z]/g, " $&").trim()}</div>
            </div>
            <DropDownButton
              className="pl-3"
              buttonClass="bg-transparent text-red-600 "
              rounded="full"
              fillMode="flat"
              svgIcon={svgIcons.moreVerticalIcon}
              onItemClick={handleItemClick}
              items={titleMenuItems}
            />
          </AppBarSection>
        </div>
      </AppBar>
    </div>
  );
}

