import { TREELIST_COL_INDEX_ATTRIBUTE, TreeList, TreeListColumnMenuFilterChangeEvent, TreeListExpandChangeEvent, TreeListHeaderSelectionCellProps, TreeListSelectionCell, TreeListSelectionChangeEvent, TreeListSortChangeEvent, createDataTree, extendDataItem, filterBy, getSelectedState, mapTree, orderBy, } from "@progress/kendo-react-treelist";
import { useCallback, useState } from "react";
import { getter } from '@progress/kendo-react-common';
import { ColumnMenuTextColumn, useTableKeyboardNavigation, } from "@progress/kendo-react-data-tools";
import { SvgIcon } from '@progress/kendo-react-common';
import { classNames } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap } from "@fortawesome/free-solid-svg-icons";

const DATA_ITEM_KEY: string = "id";
const SUB_ITEMS_FIELD: string = "treeListData";
const EXPAND_FIELD: string = "expanded";
const SELECTED_FIELD: string = "selected";
const idGetter = getter(DATA_ITEM_KEY);

interface Props {
  treeListData: any;
  updateSelectedItem: (item: any) => void;
  updateSelectedState: (state: any) => void;
  selectedState: any;
}

function GetTreeNodeIcon (type: string)
{
    switch(type)
    {
      case "Filter":
        return <SvgIcon icon={svgIcons.filterIcon} style={{width: '16px', height: '16px'}} />

      case "Account":
        return <FontAwesomeIcon icon={faSitemap} />


      case "Location":
        return <SvgIcon icon={svgIcons.globeIcon} style={{width: '16px', height: '16px'}} />
  
      default:
        return <SvgIcon icon={svgIcons.gearIcon} style={{width: '16px', height: '16px'}} />
    }
  }

  const TreeCell = (props: any) => {
    
    const field = props.field || "";
    const value = props.dataItem[field];
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    const {isSelected } = props;
    const icons = [];
    
    if (props.expandable) {
      icons.push(
        ...props.level
          .slice(1)
          .map((_x: any, i: any) => <SvgIcon key={i} icon={svgIcons.globeIcon} style={{color: "transparent"}}/>)
      );
  
      if (props.hasChildren) {
        icons.push(
          <SvgIcon 
          icon={props.expanded ? svgIcons.caretAltDownIcon : svgIcons.caretAltRightIcon}
          key="expand-collapse"
          onClick={(event) => {
            props.onExpandChange(event, props.dataItem, props.level)}}
          {...{ [TREELIST_COL_INDEX_ATTRIBUTE]: props.colIndex }}
          />
        );
      } else {
        icons.push(<SvgIcon key={icons.length} icon={svgIcons.globeIcon} style={{color:"transparent"}}/>);
      }
    }

    return (
      <td 
        aria-selected={props.isSelected} 
        className={classNames(
          'k-table-td',
          props.className,
          {
              'k-text-nowrap': props.expandable,
              'k-selected': isSelected
          })}
        {...navigationAttributes}
        {...{[TREELIST_COL_INDEX_ATTRIBUTE]: props.colIndex }}
      >
        {icons}
        {GetTreeNodeIcon(props.dataItem.type)}
        {" "}
        {value === null ? "" : props.dataItem[field].toString()}
        {" "}
      </td>
    );
  };

export const TreeListComponent = (props: Props) => {

  const dataTree = createDataTree(
    props.treeListData,
    (i) => i.id,
    (i) => i.parentId,
    SUB_ITEMS_FIELD
  );

  const extendData: (
    dataState: any[],
    selectedState: { [id: string]: boolean },
    expandedState: { [n: number]: boolean }
  ) => any[] = (dataState, selectedState, expandedState) => {
    return mapTree(dataState, SUB_ITEMS_FIELD, (item) =>
      extendDataItem(item, SUB_ITEMS_FIELD, {
        selected: selectedState[idGetter(item)],
        expanded: expandedState[idGetter(item)],
      })
    );
  };

  const headerSelectionValue = (dataState: any[], selectedState: any) => {
    let allSelected = true;
  
    mapTree(dataState, selectedState, (item) => {
      allSelected = allSelected && dataState[idGetter(item)];
      return item;
    });

    return allSelected;
  };

  const [dataState, setDataState] = useState<any>({
    data: [...dataTree],
    filter: [],
    expanded: [],
    sort: [{ field: "name", dir: "asc" }],

  });

  const [expandedState, setExpandedState] = useState<{[n: number]: boolean;}>({});

  const onExpandChange = useCallback(
    (e: TreeListExpandChangeEvent) => {
      setExpandedState({ ...expandedState, [idGetter(e.dataItem)]: !e.value });
    },
    [expandedState]
  );

  const onSelectionChange = useCallback(
    (event: TreeListSelectionChangeEvent) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: props.selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      props.updateSelectedState(newSelectedState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.selectedState]
  );

  const addExpandField = (dataTree: any) => {
    const expanded = dataState.expanded;
    return mapTree(dataTree, SUB_ITEMS_FIELD, (item) =>
      extendDataItem(item, SUB_ITEMS_FIELD, {
        [EXPAND_FIELD]: expanded.includes(item.id),
      })
    );
  };

  const processData = () => {
    const { data, filter, sort } = dataState;

    const dataTree = orderBy(
      filterBy(data, filter, SUB_ITEMS_FIELD),
      sort,
      SUB_ITEMS_FIELD
    );
    return addExpandField(dataTree);
  };

  const MyHeaderCell = (props: TreeListHeaderSelectionCellProps) => {
    return <td></td>
  };

  const onSortChange = (event: TreeListSortChangeEvent) => {
    setDataState({
      ...dataState,
      sort: event.sort,
    });
  };

  const handleFilterChange = (event: TreeListColumnMenuFilterChangeEvent) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    });
  };

  return (
    <div className="main-treelist" >
      <TreeList
        style={{ overflow: "auto", borderBottom: 'none', borderRight: 'none'}}
        data={extendData(processData(), props.selectedState, expandedState)}
        selectedField={SELECTED_FIELD}
        expandField={EXPAND_FIELD}
        subItemsField={SUB_ITEMS_FIELD}
        dataItemKey={DATA_ITEM_KEY}
        selectable={{
          enabled: true,
          mode: "multiple",
          drag: false,
        }}
        onSelectionChange={onSelectionChange}
        onExpandChange={onExpandChange}
        filter={dataState.filter}
        columnMenuFilter={dataState.filter}
        onColumnMenuFilterChange={handleFilterChange}
        onSortChange={onSortChange}
        sort={dataState.sort}
        columns={[
          {
            field: 'selected',
            width: '5%',
            headerSelectionValue: headerSelectionValue(processData(), props.selectedState),
            cell: TreeListSelectionCell,
            headerCell: MyHeaderCell
          } ,
          {
          field: "name",
          title: "Filters",
          expandable: true,
          columnMenu: ColumnMenuTextColumn,
          cell: TreeCell
        }]}
      />
    </div>
  );
};
