import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../constants/ClientRoutingConstants";
import { HealthMetricsTable } from "../components/HealthMetricsTable";
import healthMetricsApiService from "../utilities/HealthMetricsApiService";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorCard from "../../shared/components/ErrorCard";
import { AllAccountsHealth } from "../../../models/accounts/AllAccountsHealth";

export const HealthMetricsPage = () => {

  const accountsHealthMetrics = useQuery<AllAccountsHealth>(ReactQueryKeys.AllHealthMetrics, async () => {
    return healthMetricsApiService.getAllHealthMetrics();
  });

if(accountsHealthMetrics.isLoading || accountsHealthMetrics.isFetching) {
  return <LoadingSpinner/>
} else if (accountsHealthMetrics.error) {
  return <ErrorCard/>
} else
  return (
    <div className="">
      {/* <div className="gb-h2 py-2 pl-5 bg-white">Health Metrics</div> */}
      <div className="p-4">
        <HealthMetricsTable accountsHealthMetrics={accountsHealthMetrics.data!}/>
      </div>
    
    </div>
  );
};
