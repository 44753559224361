import React from 'react';
import { Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { IdentityRoutes } from './constants/ApiRoutingConstants';
import { ClientRoutes } from './constants/ClientRoutingConstants';
import AuthenticationWrapper from './features/identity/components/AuthenticationWrapper';
import useIdentityStore from './zustand/identityStore';
import LoginHandler from '../src/features/identity/pages/LoginHandler'
import { LoginActions } from './constants/IdentityConstants';
import { Home } from './features/home/pages/Home';
import authService from './features/identity/utilities/AuthorizeService';
import { AppContext } from './AppContext';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from'cldr-dates-full/main/en/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from'cldr-dates-full/main/es/dateFields.json';

import auNumbers from 'cldr-numbers-full/main/en-AU/numbers.json';
import auLocalCurrency from 'cldr-numbers-full/main/en-AU/currencies.json';
import auCaGregorian from 'cldr-dates-full/main/en-AU/ca-gregorian.json';
import auDateFields from'cldr-dates-full/main/en-AU/dateFields.json';

import ptNumbers from 'cldr-numbers-full/main/pt/numbers.json';
import ptLocalCurrency from 'cldr-numbers-full/main/pt/currencies.json';
import ptCaGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import ptDateFields from'cldr-dates-full/main/pt/dateFields.json';

import { enMessages } from './messages/en-US';
import { esMessages } from './messages/es';
import { ptMessages } from "./messages/pt-BR";
import { locales } from './messages/locales';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ExplorerPage } from './features/explorer/pages/ExplorerPage';
import { SystemPage } from './features/system/pages/SystemPage';
import { HealthMetricsPage } from './features/healthMetrics/pages/HealthMetricsPage';

load(
  likelySubtags,
  currencyData,
  weekData,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  esNumbers,
  esLocalCurrency,
  esCaGregorian,
  esDateFields,
  ptNumbers,
  ptLocalCurrency,
  ptCaGregorian,
  ptDateFields,
  auNumbers,
  auLocalCurrency,
  auCaGregorian,
  auDateFields
);

loadMessages(esMessages, 'es');
loadMessages(enMessages, 'en-US');
loadMessages(enMessages, 'en-AU');
loadMessages(ptMessages, 'pt-BR');

const generateRedirectUri = (route: any): string => {
  let link = document.createElement("a");
  link.href = route ?? "";
  const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
  const redirectUrl = `${ClientRoutes.Login}?${
    IdentityRoutes.LoginQueryParameters.ReturnUrl
  }=${encodeURIComponent(returnUrl)}`;
  return redirectUrl;
};

const ProtectedRoute = ({ children }:any) => {
  const user = useIdentityStore((store) => store.user);
  const location = useLocation();
  const redirectUrl = generateRedirectUri(location.pathname);
 
  if (user == null) {
    return <Navigate to={redirectUrl} replace />;
  }
  else
  {
    if (user.expired)
    {
      // TODO: Consider displaying a separate page if the token has expired 
      return <Navigate to={ClientRoutes.Logout} replace />;
    }
  }

  return children;
};

const RedirectToDashboard = () => {
  // Need to redirect to ensure the Drawer container is displayed. 
  return <Navigate to={ClientRoutes.Explorer} replace/>;
};

const LogoutUser = () => {
  authService.signOut(null);
  return null;
}; 

const NotFound = () => {
  //TODO: Need to make this into a formal "Page not found" component. (e.g. https://blog.fluidui.com/top-404-error-page-examples/)
  return (<div>Not Found</div>);
}; 

export default function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        retryDelay: 2000,
      },
    },
  });

  function getLang() {
    if(navigator.languages !== undefined){
      const localeArray: any = []
      for(let i = 0; i < locales.length; i++) {
        localeArray.push(locales[i].localeId)
      }
      if(localeArray.includes(navigator.languages[0])) {
        return navigator.languages[0]
      } else {
        return 'en-AU';  
      }
  
    } else {
      return 'en-AU';  
    }
  }
  
  const [contextState, setContextState] = React.useState<any>({
    localeId: getLang()
  });
  
  const onLanguageChange = React.useCallback(
      (event: any) =>  setContextState({...contextState, localeId: event.value.localeId}) ,
      [contextState, setContextState]
  );

  return (
    <AuthenticationWrapper>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider language={contextState.localeId}>
          <IntlProvider locale={contextState.localeId}>
            <AppContext.Provider value={{...contextState, onLanguageChange}}>
              <Routes>
                <Route path={ClientRoutes.Login} element={<LoginHandler action={LoginActions.Login} />} />
                <Route path={ClientRoutes.LoginCallback} element={<LoginHandler action={LoginActions.LoginCallback} />} />
                <Route path={ClientRoutes.Home} element={<Home />}>
                  <Route path={ClientRoutes.Explorer} element={<ProtectedRoute><ExplorerPage/></ProtectedRoute>} />
                  <Route path={ClientRoutes.System} element={<ProtectedRoute><SystemPage/></ProtectedRoute>} />
                  <Route path={ClientRoutes.HealthMetrics} element={<ProtectedRoute><HealthMetricsPage/></ProtectedRoute>} />
                </Route>
                <Route path={ClientRoutes.Logout} element={<LogoutUser/>} />
                <Route path="/" element={<RedirectToDashboard/>} />
                <Route path="*" element={<NotFound/>} />
              </Routes>
              </AppContext.Provider>
            </IntlProvider>
        </LocalizationProvider>
      </QueryClientProvider>
     </AuthenticationWrapper>
  );
}
