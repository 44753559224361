import { AppBar, AppBarSection } from '@progress/kendo-react-layout';
import * as React from 'react';
import { AppContext } from '../../../AppContext'
import { useLocalization } from '@progress/kendo-react-intl';
import authService from '../../identity/utilities/AuthorizeService';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

const titleMenuItems = [
  {
    text: 'Logout', 
    onClick: () =>  authService.signOut(null)
  }
  ]

export const Footer = (props: any) => {
  const {localeId, onLanguageChange } = React.useContext<any>(AppContext);
  const localizationService = useLocalization();

  return (
    <div className="">
      <AppBar themeColor="inherit"  className='flex justify-end' position={'bottom'} positionMode={'fixed'} style={{background: 'white', zIndex:'5'}}>
            <AppBarSection className=''>
              <SvgIcon name="twitter" icon={svgIcons.twitterIcon} style={{color:'#323543', cursor:'pointer', marginRight:'8px'}} onClick={()=> window.open("https://twitter.com/greaseboss1", "_blank", "noopener,noreferrer")} />
              <SvgIcon name="facebook" icon={svgIcons.facebookIcon} style={{color:'#323543', cursor:'pointer', marginRight:'8px'}} onClick={()=> window.open("https://www.facebook.com/greasebossaustralia/", "_blank", "noopener,noreferrer")} />
              <SvgIcon name="linkedin" icon={svgIcons.linkedinIcon} style={{color:'#323543', cursor:'pointer', marginRight:'20px'}} onClick={()=> window.open("https://www.linkedin.com/company/greaseboss/", "_blank", "noopener,noreferrer")} />
            </AppBarSection>

            <AppBarSection className='' style={{color:'#323543', fontSize:'11px', marginRight:'20px'}}>
              <SvgIcon name="info" icon={svgIcons.infoCircleIcon} style={{color:'#323543', cursor:'pointer', marginRight:'2px'}} />
              <div className='' >Privacy Policy</div>
            </AppBarSection>
            <AppBarSection className='mr-5' style={{color:'#323543', fontSize:'11px'}}>
              <div> &copy; Copyright 2023 GreaseBoss. All rights reserved</div>
            </AppBarSection>
      </AppBar>
    </div>
  );
}

