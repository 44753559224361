import { useQuery } from "react-query";
import accountsApiService from "../../utilities/ExplorerApiService";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorCard from "../../../shared/components/ErrorCard";
import { FilterHealthTable } from "../../components/Health/FilterHealthTable";
import { useEffect } from "react";

interface Props {
  selectedItems: any;
}

export const FilterHealthTabPage = (props: Props) => {

  const selectedFilterHealthsQuery = useQuery<any>("filterHealthMetrics", async () => {
    return accountsApiService.getFilterHealthMetrics(props.selectedItems);
  }, );

  useEffect(() => {
    selectedFilterHealthsQuery.refetch()
  }, [props.selectedItems]);


if(selectedFilterHealthsQuery.isLoading || selectedFilterHealthsQuery.isFetching) {
  return <LoadingSpinner/>
} else if (selectedFilterHealthsQuery.error) {
  return <ErrorCard/>
} else
  return (
<div>

<FilterHealthTable selectedItems={selectedFilterHealthsQuery.data}/>
</div>
  );
}