export const ptMessages = {
    "custom": {
       "dashboard": "Painel"

    },
    "grid": {
        "groupPanelEmpty": "Arraste um cabeçalho de coluna e solte-o aqui para agrupar por essa coluna",
        "pagerItemsPerPage": "itens por página",
        "pagerInfo": "{0} - {1} de {2} itens",
        "pagerFirstPage": "Vá para a primeira página",
        "pagerPreviousPage": "Vá para a página anterior",
        "pagerNextPage": "Vá para a página seguinte",
        "pagerLastPage": "Vá para a última página",
        "pagerPage": "Página",
        "pagerOf": "de",
        "filterClearButton": "Claro",
        "filterEqOperator": "É igual a",
        "filterNotEqOperator": "Não é igual a",
        "filterIsNullOperator": "É nulo",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterStartsWithOperator": "Começa com",
        "filterContainsOperator": "Contém",
        "filterNotContainsOperator": "Não contém",
        "filterEndsWithOperator": "Termina com",
        "filterGteOperator": "É maior que ou igual a",
        "filterGtOperator": "É melhor que",
        "filterLteOperator": "É menor ou igual a",
        "filterLtOperator": "É menos do que",
        "filterIsTrue": "É verdade",
        "filterIsFalse": "É falso",
        "filterBooleanAll": "(Tudo)",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterAfterOperator": "É depois",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "noRecords": "Nenhum registro disponível.",
        "filterAriaLabel": "Filtro",
        "filterCheckAll": "Verificar tudo",
        "filterChooseOperator": "Escolha o operador",
        "filterSelectedItems": "itens selecionados",
        "filterSubmitButton": "Filtro",
        "filterTitle": "Filtro",
        "filterAndLogic": "E",
        "filterOrLogic": "Ou",
        "groupColumn": "Coluna de Grupo",
        "pagerTotalPages": "{0}",
        "searchPlaceholder": "Procurar",
        "sortAriaLabel": "Classificável",
        "sortAscending": "Ordernar ascendente",
        "sortDescending": "Classificar em ordem decrescente",
        "ungroupColumn": "Desagrupar coluna",
    },
    "filter": {
        "eqOperator": "É igual a",
        "notEqOperator": "Não é igual a",
        "isNullOperator": "É nulo",
        "isNotNullOperator": "Não é nulo",
        "isEmptyOperator": "Está vazia",
        "isNotEmptyOperator": "Não está vazio",
        "startsWithOperator": "Começa com",
        "containsOperator": "Contém",
        "notContainsOperator": "Não contém",
        "endsWithOperator": "Termina com",
        "gteOperator": "É maior que ou igual a",
        "gtOperator": "É melhor que",
        "lteOperator": "É menor ou igual a",
        "ltOperator": "É menos do que",
        "isTrue": "É verdade",
        "isFalse": "É falso",
        "afterOrEqualOperator": "É posterior ou igual a",
        "afterOperator": "É depois",
        "beforeOperator": "É antes",
        "beforeOrEqualOperator": "É anterior ou igual a",
        "andLogic": "E",
        "orLogic": "Ou",
        "addExpression": "Adicionar Expressão",
        "addGroup": "Adicionar grupo",
        "close": "Fechar",
        "filterAriaLabel": "Filtro"
    },
    "dateinput": {
        "increment": "Aumente o valor",
        "decrement": "Diminuir valor"
    },
    "calendar": {
        "today": "HOJE"
    },
    "datepicker": {
        "toggleCalendar": "Alternar calendário"
    },
    "daterangepicker": {
        "swapStartEnd": "Troque os valores inicial e final",
        "start": "Começar",
        "end": "Fim",
        "separator": " "
    },
    "datetimepicker": {
        "date": "Encontro",
        "time": "Tempo",
        "toggleDateTimeSelector": "Alternar seletor de data e hora",
        "cancel": "Cancelar",
        "set": "Definir"
    },
    "editor": {
        "bold": "Negrito",
        "italic": "itálico",
        "underline": "Sublinhado",
        "strikethrough": "Tachado",
        "subscript": "Subscrito",
        "superscript": "Sobrescrito",
        "unlink": "Remover hiperlink",
        "undo": "Desfazer",
        "redo": "Refazer",
        "fontSize": "Tamanho da fonte",
        "fontName": "Nome da fonte",
        "format": "Formato",
        "alignLeft": "Alinhar o texto à esquerda",
        "alignRight": "Alinhar o texto à direita",
        "alignCenter": "Texto central",
        "alignJustify": "Justificar",
        "indent": "Recuar",
        "outdent": "Outdent",
        "orderedList": "Inserir lista ordenada",
        "bulletList": "Inserir lista não ordenada",
        "createTable": "Crie uma mesa",
        "insertTableHint": "Crie uma tabela {0} x {1}",
        "addRowBefore": "Adicionar linha acima",
        "addRowAfter": "Adicionar linha abaixo",
        "addColumnBefore": "Adicionar coluna à esquerda",
        "addColumnAfter": "Adicionar coluna à direita",
        "deleteRow": "Apagar linha",
        "deleteColumn": "Apagar coluna",
        "deleteTable": "Apagar mesa",
        "mergeCells": "Mesclar células",
        "splitCell": "Dividir célula",
        "hyperlink": "Insira o hyperlink",
        "hyperlink-dialog-title": "Insira o hyperlink",
        "hyperlink-dialog-content-address": "Endereço da web",
        "hyperlink-dialog-content-title": "Título",
        "hyperlink-dialog-content-newwindow": "Abrir link em nova janela",
        "hyperlink-dialog-cancel": "Cancelar",
        "hyperlink-dialog-insert": "Inserir",
        "image": "Inserir Imagem",
        "image-dialog-title": "Inserir Imagem",
        "image-address": "Endereço da web",
        "image-title": "Título",
        "image-altText": "Texto alternativo",
        "image-width": "Largura (px)",
        "image-height": "Altura (px)",
        "image-cancel": "Cancelar",
        "image-insert": "Inserir",
        "viewHtml": "Ver HTML",
        "viewHtml-dialog-title": "Ver HTML",
        "viewHtml-cancel": "Cancelar",
        "viewHtml-update": "Atualizar",
        "print": "Imprimir",
        "selectAll": "Selecionar tudo",
        "cleanFormatting": "Limpar formatação",
        "pdf": "Exportar como PDF",
        "foregroundColor": "Cor",
        "backgroundColor": "Cor de fundo",
        "insertFile": "Inserir arquivo",
        "insertFileDialogTitle": "Inserir arquivo",
        "insertFileAddress": "Endereço da web",
        "insertFileTitle": "Título",
        "insertFileCancel": "Cancelar",
        "insertFileInsert": "Inserir",
        "findReplaceToolTitle": "Encontrar e substituir",
        "findReplaceDialogTitle": "Encontrar e substituir",
        "findReplaceTabFind": "Achar",
        "findReplaceTabReplace": "Substituir",
        "findReplaceFindWhat": "Encontre o que:",
        "findReplaceReplaceWith": "Substituir com",
        "findReplaceReplace": "Substituir",
        "findReplaceReplaceAll": "Substitua tudo",
        "findReplaceMatchCase": "Caso de compatibilidade",
        "findReplaceMatchWord": "Corresponder apenas a palavra inteira",
        "findReplaceMatchCyclic": "Match cyclic (Wrap around)",
        "findReplaceUseRegExp": "Expressão regular",
        "findReplacePrevMatch": "Anterior",
        "findReplaceNextMatch": "Próximo",
        "findReplaceMatches": "{0} de {1} partidas"
    },
    "timepicker": {
        "now": "AGORA",
        "selectNow": "Selecione agora",
        "cancel": "Cancelar",
        "set": "Definir",
        "toggleTimeSelector": "Alternar TimeSelector",
        "toggleClock": "Alternar relógio"
    },
    "dropdowns": {
        "nodata": "NENHUM DADO ENCONTRADO.",
        "clear": "Claro"
    },
    "numerictextbox": {
        "increment": "Aumente o valor",
        "decrement": "Diminuir valor"
    },
    "upload": {
        "cancel": "Cancelar",
        "clearSelectedFiles": "Claro",
        "dropFilesHere": "Arraste e solte o arquivo aqui para enviar",
        "headerStatusUploaded": "Feito",
        "headerStatusUploading": "Enviando...",
        "invalidFileExtension": "Tipo de arquivo não permitido.",
        "invalidFiles": "Arquivo (s) inválido (s). Verifique os requisitos de upload de arquivo.",
        "invalidMaxFileSize": "O tamanho do arquivo é muito grande.",
        "invalidMinFileSize": "Tamanho do arquivo muito pequeno.",
        "remove": "Remover",
        "retry": "Tentar novamente",
        "select": "Selecionar arquivo...",
        "uploadSelectedFiles": "Envio",
        "total": "Total",
        "files": "Arquivos",
        "dropZoneHint": "Arraste e solte os arquivos aqui para fazer o upload.",
        "dropZoneNote": "Somente arquivos JPEG e PNG são permitidos.",
        "statusUploadFailed": "Falha ao carregar arquivo (s).",
        "statusUploaded": "Arquivo (s) carregado (s) com sucesso."
    },
    "sortable": {
        "noData": "Sem dados"
    },
    "pager": {
        "itemsPerPage": "itens por página",
        "info": "{0} - {1} de {2} itens",
        "firstPage": "Vá para a primeira página",
        "previousPage": "Vá para a página anterior",
        "nextPage": "Vá para a página seguinte",
        "lastPage": "Vá para a última página",
        "page": "Página",
        "of": "de",
        "pagerTotalPages": "{0}"
    },
    "treelist": {
        "filterClearButton": "Claro",
        "filterEqOperator": "É igual a",
        "filterNotEqOperator": "Não é igual a",
        "filterIsNullOperator": "É nulo",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterStartsWithOperator": "Começa com",
        "filterContainsOperator": "Contém",
        "filterNotContainsOperator": "Não contém",
        "filterEndsWithOperator": "Termina com",
        "filterGteOperator": "É maior que ou igual a",
        "filterGtOperator": "É melhor que",
        "filterLteOperator": "É menor ou igual a",
        "filterLtOperator": "É menos do que",
        "filterIsTrue": "É verdade",
        "filterIsFalse": "É falso",
        "filterBooleanAll": "(Tudo)",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterAfterOperator": "É depois",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "noRecords": "Nenhum registro disponível"
    },
    "scheduler": {
        "editorValidationRequired": "Campo é obrigatório.",
        "editorValidationStart": "O horário de início deve ser anterior ao horário de término.",
        "editorValidationEnd": "O horário de término deve ser posterior ao horário de início.",
        "allEvents": "Todos os eventos",
        "allDay": "dia todo",
        "today": "Hoje",
        "dayViewTitle": "Dia",
        "monthViewTitle": "Mês",
        "weekViewTitle": "Semana",
        "workWeekViewTitle": "Semana de trabalho",
        "timelineViewTitle": "Linha do tempo",
        "agendaViewTitle": "Agenda",
        "deleteTitle": "Excluir",
        "previousTitle": "Anterior",
        "nextTitle": "Próximo",
        "showFullDay": "Mostrar o dia inteiro",
        "showWorkDay": "Mostrar horário comercial",
        "editorOccurrence": "Editar ocorrência atual",
        "editorSeries": "Edite a série",
        "editorRecurringConfirmation": "Quer editar apenas esta ocorrência de evento ou toda a série?",
        "editorRecurringDialogTitle": "Editar item recorrente",
        "editorSave": "Salve ",
        "editorCancel": "Cancelar",
        "editorDelete": "Excluir",
        "editorTitle": "Evento",
        "editorEventTitle": "Título",
        "editorEventStart": "Começar",
        "editorEventStartTimeZone": "Fuso Horário de Início",
        "editorEventEnd": "Fim",
        "editorEventEndTimeZone": "Fuso Horário Final",
        "editorEventAllDay": "Evento de dia inteiro",
        "editorEventDescription": "Descrição",
        "editorEventSeparateTimeZones": "Termina em fuso horário diferente",
        "editorEventTimeZone": "Especifique o fuso horário",
        "recurrenceEditorRepeat": "Repita",
        "recurrenceEditorDailyInterval": "dias)",
        "recurrenceEditorDailyRepeatEvery": "Repita a cada",
        "recurrenceEditorWeeklyInterval": "semana (s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repita a cada",
        "recurrenceEditorWeeklyRepeatOn": "Repita",
        "recurrenceEditorMonthlyDay": "Dia",
        "recurrenceEditorMonthlyInterval": "meses)",
        "recurrenceEditorMonthlyRepeatEvery": "Repita a cada",
        "recurrenceEditorMonthlyRepeatOn": "Repita",
        "recurrenceEditorYearlyOf": "de",
        "recurrenceEditorYearlyRepeatEvery": "Repita a cada",
        "recurrenceEditorYearlyRepeatOn": "Repita",
        "recurrenceEditorYearlyInterval": "anos)",
        "recurrenceEditorFrequenciesDaily": "Diário",
        "recurrenceEditorFrequenciesMonthly": "Por mês",
        "recurrenceEditorFrequenciesNever": "Nunca",
        "recurrenceEditorFrequenciesWeekly": "Semanalmente",
        "recurrenceEditorFrequenciesYearly": "Anual",
        "recurrenceEditorOffsetPositionsFirst": "Primeiro",
        "recurrenceEditorOffsetPositionsSecond": "Segundo",
        "recurrenceEditorOffsetPositionsThird": "Terceiro",
        "recurrenceEditorOffsetPositionsFourth": "Quarto",
        "recurrenceEditorOffsetPositionsLast": "Durar",
        "recurrenceEditorWeekdaysDay": "Dia",
        "recurrenceEditorWeekdaysWeekday": "Dia da semana",
        "recurrenceEditorWeekdaysWeekendday": "Dia de fim de semana",
        "recurrenceEditorEndAfter": "Depois de",
        "recurrenceEditorEndOccurrence": "ocorrência (s)",
        "recurrenceEditorEndLabel": "Fim",
        "recurrenceEditorEndNever": "Nunca",
        "recurrenceEditorEndOn": "Sobre",
        "deleteConfirmation": "Tem certeza que deseja deletar este evento?",
        "deleteRecurringConfirmation": "Quer excluir apenas esta ocorrência do evento ou toda a série?",
        "deleteOccurrence": "Apagar a ocorrência atual",
        "deleteSeries": "Exclua a série",
        "deleteDialogTitle": "Apagar Evento",
        "deleteRecurringDialogTitle": "Excluir item recorrente",
        "dateTitle": "Encontro",
        "timeTitle": "Tempo",
        "eventTitle": "Evento",
        "noEvents": "Sem eventos"
    },
    "listbox": {
        "moveUp": "Subir",
        "moveDown": "Mover para Baixo",
        "transferTo": "Transferir para",
        "transferFrom": "Transferir de",
        "transferAllTo": "Transferir tudo para",
        "transferAllFrom": "Transferir tudo de",
        "remove": "Excluir"
    },
    "stepper": {
        "optionalText": "(Opcional)"
    },
    "labels": {
        "labelsOptional": "(Opcional)"
    },
    "slider": {
        "increment": "Aumentar",
        "decrement": "Diminuir",
        "dragTitle": "Arrastar"
    },
    "colorGradient": {
        "r": "r",
        "g": "g",
        "b": "b",
        "a": "uma",
        "hex": "hex",
        "contrastRatio": "Relação de contraste",
        "colorGradientAALevel": "AA",
        "colorGradientAAALevel": "AAA",
        "colorGradientPass": "Passar",
        "colorGradientFail": "Falhou"
    },
    "checkbox": {
        "validation": "Marque esta caixa se quiser continuar!",
        "optionalText": "(Opcional)"
    },
    "radioButton": {
        "validation": "Selecione a opção se quiser continuar!"
    },
    "switch": {
        "validation": "Ligue se quiser continuar!"
    },
    "gantt": {
        "weekViewTitle": "Semana",
        "dayViewTitle": "Dia",
        "monthViewTitle": "Mês",
        "yearViewTitle": "Ano",
        "filterClearButton": "Claro",
        "filterEqOperator": "É igual a",
        "filterNotEqOperator": "Não é igual a",
        "filterIsNullOperator": "É nulo",
        "filterIsNotNullOperator": "Não é nulo",
        "filterIsEmptyOperator": "Está vazia",
        "filterIsNotEmptyOperator": "Não está vazio",
        "filterStartsWithOperator": "Começa com",
        "filterContainsOperator": "Contém",
        "filterNotContainsOperator": "Não contém",
        "filterEndsWithOperator": "Termina com",
        "filterGteOperator": "É maior que ou igual a",
        "filterGtOperator": "É melhor que",
        "filterLteOperator": "É menor ou igual a",
        "filterLtOperator": "É menos do que",
        "filterIsTrue": "É verdade",
        "filterIsFalse": "É falso",
        "filterBooleanAll": "(Tudo)",
        "filterAfterOrEqualOperator": "É posterior ou igual a",
        "filterAfterOperator": "É depois",
        "filterBeforeOperator": "É antes",
        "filterBeforeOrEqualOperator": "É anterior ou igual a",
        "noRecords": "Nenhum registro disponível",
        "editorSave": "Salve ",
        "editorCancel": "Cancelar",
        "editorTitle": "Tarefa",
        "editorTaskTitle": "Título",
        "editorTaskStart": "Começar",
        "editorTaskEnd": "Fim",
        "editorTaskComplete": "Completo",
        "editorValidationRequired": "Campo é obrigatório.",
        "editorValidationStart": "O horário de início deve ser anterior ao horário de término.",
        "editorValidationEnd": "O horário de término deve ser posterior ao horário de início.",
        "addTask": "Adicionar Tarefa",
        "addChild": "Adicionar criança",
        "addAbove": "Adicionar Acima",
        "addBelow": "Adicionar abaixo",
        "editorDelete": "Excluir",
        "deleteConfirmation": "Tem certeza que deseja deletar este evento?",
        "deleteDialogTitle": "Apagar Evento"
    },
    "multiviewcalendar": {
        "prevView": "Navegue para a vista anterior",
        "nextView": "Navegue para a próxima vista"
    },
    "columnMenu": {
        "columnMenuFilterClearButton": "Claro",
        "columnMenuFilterSubmitButton": "Filtro",
        "columnMenuFilterTitle": "Filtro",
        "columnMenuSortAscending": "Ordernar ascendente",
        "columnMenuSortDescending": "Classificar em ordem decrescente",
        "columnMenuFilterEqOperator": "É igual a",
        "columnMenuFilterNotEqOperator": "Não é igual a",
        "columnMenuFilterIsNullOperator": "É nulo",
        "columnMenuFilterIsNotNullOperator": "Não é nulo",
        "columnMenuFilterIsEmptyOperator": "Está vazia",
        "columnMenuFilterIsNotEmptyOperator": "Não está vazio",
        "columnMenuFilterStartsWithOperator": "Começa com",
        "columnMenuFilterContainsOperator": "Contém",
        "columnMenuFilterNotContainsOperator": "Não contém",
        "columnMenuFilterEndsWithOperator": "Termina com",
        "columnMenuFilterGteOperator": "É maior que ou igual a",
        "columnMenuFilterGtOperator": "É melhor que",
        "columnMenuFilterLteOperator": "É menor ou igual a",
        "columnMenuFilterLtOperator": "É menos do que",
        "columnMenuFilterIsTrue": "É verdade",
        "columnMenuFilterAfterOrEqualOperator": "É posterior ou igual a",
        "columnMenuFilterAfterOperator": "É depois",
        "columnMenuFilterBeforeOperator": "É antes",
        "columnMenuFilterBeforeOrEqualOperator": "É anterior ou igual a",
        "columnMenuFilterAndLogic": "E",
        "columnMenuFilterOrLogic": "Ou"
    },
    "pivotgrid": {
        "fieldMenuReset": "Redefinir",
        "fieldMenuApply": "Aplicar",
        "configuratorCancel": "Cancelar",
        "configuratorApply": "Aplicar",
        "configuratorTitle": "Configurações",
        "configuratorEditorSearchTitle": "Campos",
        "configuratorEditorSearchPlaceholder": "Procurar...",
        "configuratorColumnsTitle": "Colunas",
        "configuratorRowsTitle": "Linhas",
        "configuratorMeasuresTitle": "Valores",
        "configuratorButtonLabel": "Mudar configurações",
        "configuratorMissingFields": "Selecione alguns campos para iniciar a configuração"
    }
};