export const enMessages = {
    "custom": {
        "dashboard": "Dashboard",
        "loading": "Loading...",
    },

    "grid": {
        "groupPanelEmpty": "Drag a column header and drop it here to group by that column",
        "pagerItemsPerPage": "items per page",
        "pagerInfo": "{0} - {1} of {2} items",
        "pagerFirstPage": "Go to the first page",
        "pagerPreviousPage": "Go to the previous page",
        "pagerNextPage": "Go to the next page",
        "pagerLastPage": "Go to the last page",
        "pagerPage": "Page",
        "pagerOf": "of",
        "pagerTotalPages": "{0}",
        "filterClearButton": "Clear",
        "filterEqOperator": "Is equal to",
        "filterNotEqOperator": "Is not equal to",
        "filterIsNullOperator": "Is null",
        "filterIsNotNullOperator": "Is not null",
        "filterIsEmptyOperator": "Is empty",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterStartsWithOperator": "Starts with",
        "filterContainsOperator": "Contains",
        "filterNotContainsOperator": "Does not contain",
        "filterEndsWithOperator": "Ends with",
        "filterGteOperator": "Is greater than or equal to",
        "filterGtOperator": "Is greater than",
        "filterLteOperator": "Is less than or equal to",
        "filterLtOperator": "Is less than",
        "filterIsTrue": "Is true",
        "filterIsFalse": "Is false",
        "filterBooleanAll": "(All)",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterAfterOperator": "Is after",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "noRecords": "No records available.",
        "filterAriaLabel": "Filter",
        "filterCheckAll": "Check All",
        "filterChooseOperator": "Choose Operator",
        "filterSelectedItems": "selected items",
        "filterSubmitButton": "Filter",
        "filterTitle": "Filter",
        "filterAndLogic": "And",
        "filterOrLogic": "Or",
        "groupColumn": "Group Column",
        "searchPlaceholder": "Search",
        "sortAriaLabel": "Sortable",
        "sortAscending": "Sort Ascending",
        "sortDescending": "Sort Descending",
        "ungroupColumn": "Ungroup Column"
      },
      "filter": {
        "eqOperator": "Is equal to",
        "notEqOperator": "Is not equal to",
        "isNullOperator": "Is null",
        "isNotNullOperator": "Is not null",
        "isEmptyOperator": "Is empty",
        "isNotEmptyOperator": "Is not empty",
        "startsWithOperator": "Starts with",
        "containsOperator": "Contains",
        "notContainsOperator": "Does not contain",
        "endsWithOperator": "Ends with",
        "gteOperator": "Is greater than or equal to",
        "gtOperator": "Is greater than",
        "lteOperator": "Is less than or equal to",
        "ltOperator": "Is less than",
        "isTrue": "Is true",
        "isFalse": "Is false",
        "afterOrEqualOperator": "Is after or equal to",
        "afterOperator": "Is after",
        "beforeOperator": "Is before",
        "beforeOrEqualOperator": "Is before or equal to",
        "andLogic": "And",
        "orLogic": "Or",
        "addExpression": "Add Expression",
        "addGroup": "Add Group",
        "close": "Close",
        "filterAriaLabel": "Filter"
      },
      "dateinput": {
        "increment": "Increase value",
        "decrement": "Decrease value"
      },
      "calendar": {
        "today": "TODAY"
      },
      "colorPicker": {
        "dropdownButtonAriaLabel": "Select"
      },
      "datepicker": {
        "toggleCalendar": "Toggle calendar"
      },
      "daterangepicker": {
        "swapStartEnd": "Swap start and end values",
        "start": "Start",
        "end": "End",
        "separator": " "
      },
      "datetimepicker": {
        "date": "Date",
        "time": "Time",
        "toggleDateTimeSelector": "Toggle date-time selector",
        "cancel": "Cancel",
        "set": "Set"
      },
      "editor": {
        "addColumnAfter": "Add column on the right",
        "addColumnBefore": "Add column on the left",
        "addRowAfter": "Add row below",
        "addRowBefore": "Add row above",
        "alignCenter": "Center text",
        "alignJustify": "Justify",
        "alignLeft": "Align text left",
        "alignRight": "Align text right",
        "backgroundColor": "Background color",
        "bold": "Bold",
        "bulletList": "Insert unordered list",
        "cleanFormatting": "Clean formatting",
        "createTable": "Create a table",
        "deleteColumn": "Delete column",
        "deleteRow": "Delete row",
        "deleteTable": "Delete table",
        "findReplace-dialog-title": "Find and Replace",
        "findReplace-findWhat": "Find What:",
        "findReplace-matchCase": "Match Case",
        "findReplace-matchCyclic": "Match cyclic (Wrap around)",
        "findReplace-matchWord": "Match whole word only",
        "findReplace-matches": "{0} of {1} matches",
        "findReplace-nextMatch": "Next",
        "findReplace-prevMatch": "Prev",
        "findReplace-replace": "Replace",
        "findReplace-replaceAll": "Replace All",
        "findReplace-replaceWith": "Replace With",
        "findReplace-tab-find": "Find",
        "findReplace-tab-replace": "Replace",
        "findReplace-tool-title": "Find and Replace",
        "findReplace-useRegExp": "Regular Expression",
        "fontName": "Font Name",
        "fontSize": "Font Size",
        "foregroundColor": "Color",
        "format": "Format",
        "hyperlink": "Insert hyperlink",
        "hyperlink-dialog-cancel": "Cancel",
        "hyperlink-dialog-content-address": "Web address",
        "hyperlink-dialog-content-newwindow": "Open link in new window",
        "hyperlink-dialog-content-title": "Title",
        "hyperlink-dialog-insert": "Insert",
        "hyperlink-dialog-title": "Insert hyperlink",
        "image": "Insert image",
        "image-address": "Web address",
        "image-altText": "Alternate text",
        "image-cancel": "Cancel",
        "image-dialog-title": "Insert image",
        "image-height": "Height (px)",
        "image-insert": "Insert",
        "image-title": "Title",
        "image-width": "Width (px)",
        "indent": "Indent",
        "insertFile": "Insert file",
        "insertTableHint": "Create a {0} x {1} table",
        "insertfile-dialog-cancel": "Cancel",
        "insertfile-dialog-content-address": "Web address",
        "insertfile-dialog-content-title": "Title",
        "insertfile-dialog-insert": "Insert",
        "insertfile-dialog-title": "Insert file",
        "italic": "Italic",
        "mergeCells": "Merge cells",
        "orderedList": "Insert ordered list",
        "outdent": "Outdent",
        "pdf": "Export as PDF",
        "print": "Print",
        "redo": "Redo",
        "selectAll": "Select All",
        "splitCell": "Split cell",
        "strikethrough": "Strikethrough",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "underline": "Underline",
        "undo": "Undo",
        "unlink": "Remove hyperlink",
        "viewHtml": "View HTML",
        "viewHtml-cancel": "Cancel",
        "viewHtml-dialog-title": "View HTML",
        "viewHtml-update": "Update"
      },
      "flatColorPicker": {
        "cancelBtn": "Cancel",
        "applyBtn": "Apply"
      },
      "timepicker": {
        "now": "NOW",
        "selectNow": "Select Now",
        "cancel": "Cancel",
        "set": "Set",
        "toggleTimeSelector": "Toggle TimeSelector",
        "toggleClock": "Toggle Clock"
      },
      "dropdowns": {
        "nodata": "NO DATA FOUND.",
        "clear": "Clear"
      },
      "numerictextbox": {
        "increment": "Increase value",
        "decrement": "Decrease value"
      },
      "upload": {
        "cancel": "Cancel",
        "clearSelectedFiles": "Clear",
        "defaultDropZoneNote": "",
        "dropFilesHere": "Drop file here to upload",
        "dropZoneHint": "Drag and drop files here to upload.",
        "dropZoneNote": "Only {0} files are allowed.",
        "files": "files",
        "headerStatusUploaded": "Done",
        "headerStatusUploading": "Processing...",
        "invalidFileExtension": "File type not allowed.",
        "invalidFiles": "Invalid file(s). Please check file upload requirements.",
        "invalidMaxFileSize": "File size too large.",
        "invalidMinFileSize": "File size too small.",
        "remove": "Remove",
        "retry": "Retry",
        "select": "Select file...",
        "selectNoFilesTitle": "No files selected",
        "selectTitle": "Press to select more files",
        "statusUploadFailed": "File(s) failed to upload.",
        "statusUploaded": "File(s) successfully uploaded.",
        "total": "Total",
        "uploadSelectedFiles": "Upload"
      },
      "sortable": {
        "noData": "No Data"
      },
      "pager": {
        "itemsPerPage": "items per page",
        "info": "{0} - {1} of {2} items",
        "firstPage": "Go to the first page",
        "previousPage": "Go to the previous page",
        "nextPage": "Go to the next page",
        "lastPage": "Go to the last page",
        "page": "Page",
        "of": "of",
        "pagerTotalPages": "{0}"
      },
      "treelist": {
        "filterClearButton": "Clear",
        "filterEqOperator": "Is equal to",
        "filterNotEqOperator": "Is not equal to",
        "filterIsNullOperator": "Is null",
        "filterIsNotNullOperator": "Is not null",
        "filterIsEmptyOperator": "Is empty",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterStartsWithOperator": "Starts with",
        "filterContainsOperator": "Contains",
        "filterNotContainsOperator": "Does not contain",
        "filterEndsWithOperator": "Ends with",
        "filterGteOperator": "Is greater than or equal to",
        "filterGtOperator": "Is greater than",
        "filterLteOperator": "Is less than or equal to",
        "filterLtOperator": "Is less than",
        "filterIsTrue": "Is true",
        "filterIsFalse": "Is false",
        "filterBooleanAll": "(All)",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterAfterOperator": "Is after",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "noRecords": "No records available"
      },
      "scheduler": {
        "editorValidationRequired": "Field is required.",
        "editorValidationStart": "Start time must be before End time.",
        "editorValidationEnd": "End time must be after Start time.",
        "allEvents": "All events",
        "allDay": "all day",
        "today": "Today",
        "dayViewTitle": "Day",
        "monthViewTitle": "Month",
        "weekViewTitle": "Week",
        "workWeekViewTitle": "Work Week",
        "timelineViewTitle": "Timeline",
        "agendaViewTitle": "Agenda",
        "deleteTitle": "Delete",
        "previousTitle": "Previous",
        "nextTitle": "Next",
        "showFullDay": "Show full day",
        "showWorkDay": "Show business hours",
        "editorOccurrence": "Edit current occurrence",
        "editorSeries": "Edit the series",
        "editorRecurringConfirmation": "Do you want to edit only this event occurrence or the whole series?",
        "editorRecurringDialogTitle": "Edit Recurring Item",
        "editorSave": "Save",
        "editorCancel": "Cancel",
        "editorDelete": "Delete",
        "editorTitle": "Event",
        "editorEventTitle": "Title",
        "editorEventStart": "Start",
        "editorEventStartTimeZone": "Start Time Zone",
        "editorEventEnd": "End",
        "editorEventEndTimeZone": "End Time Zone",
        "editorEventAllDay": "All Day Event",
        "editorEventDescription": "Description",
        "editorEventSeparateTimeZones": "End in different Time Zone",
        "editorEventTimeZone": "Specify Time Zone",
        "recurrenceEditorRepeat": "Repeat",
        "recurrenceEditorDailyInterval": "day(s)",
        "recurrenceEditorDailyRepeatEvery": "Repeat every",
        "recurrenceEditorWeeklyInterval": "week(s)",
        "recurrenceEditorWeeklyRepeatEvery": "Repeat every",
        "recurrenceEditorWeeklyRepeatOn": "Repeat on",
        "recurrenceEditorMonthlyDay": "Day",
        "recurrenceEditorMonthlyInterval": "month(s)",
        "recurrenceEditorMonthlyRepeatEvery": "Repeat every",
        "recurrenceEditorMonthlyRepeatOn": "Repeat on",
        "recurrenceEditorYearlyOf": "of",
        "recurrenceEditorYearlyRepeatEvery": "Repeat every",
        "recurrenceEditorYearlyRepeatOn": "Repeat on",
        "recurrenceEditorYearlyInterval": "year(s)",
        "recurrenceEditorFrequenciesDaily": "Daily",
        "recurrenceEditorFrequenciesMonthly": "Monthly",
        "recurrenceEditorFrequenciesNever": "Never",
        "recurrenceEditorFrequenciesWeekly": "Weekly",
        "recurrenceEditorFrequenciesYearly": "Yearly",
        "recurrenceEditorOffsetPositionsFirst": "First",
        "recurrenceEditorOffsetPositionsSecond": "Second",
        "recurrenceEditorOffsetPositionsThird": "Third",
        "recurrenceEditorOffsetPositionsFourth": "Fourth",
        "recurrenceEditorOffsetPositionsLast": "Last",
        "recurrenceEditorWeekdaysDay": "Day",
        "recurrenceEditorWeekdaysWeekday": "Weekday",
        "recurrenceEditorWeekdaysWeekendday": "Weekend Day",
        "recurrenceEditorEndAfter": "After",
        "recurrenceEditorEndOccurrence": "occurrence(s)",
        "recurrenceEditorEndLabel": "End",
        "recurrenceEditorEndNever": "Never",
        "recurrenceEditorEndOn": "On",
        "deleteConfirmation": "Are you sure you want to delete this event?",
        "deleteRecurringConfirmation": "Do you want to delete only this event occurrence or the whole series?",
        "deleteOccurrence": "Delete current occurrence",
        "deleteSeries": "Delete the series",
        "deleteDialogTitle": "Delete Event",
        "deleteRecurringDialogTitle": "Delete Recurring Item",
        "dateTitle": "Date",
        "timeTitle": "Time",
        "eventTitle": "Event",
        "noEvents": "no events"
      },
      "listbox": {
        "moveUp": "Move Up",
        "moveDown": "Move Down",
        "transferTo": "Transfer To",
        "transferFrom": "Transfer From",
        "transferAllTo": "Transfer All To",
        "transferAllFrom": "Transfer All From",
        "remove": "Delete"
      },
      "stepper": {
        "optionalText": "(Optional)"
      },
      "taskBoard": {
        "addCardButton": "Add card",
        "addCardCreateButton": "Create",
        "addCardDescriptionLabel": "Description",
        "addCardPriorityLabel": "Priority",
        "addCardTitle": "Create new card",
        "addCardTitleLabel": "Title",
        "deleteCardButton": "Delete card",
        "deleteColumnButton": "Delete column",
        "deleteColumnCancelButton": "Cancel",
        "deleteColumnConfirmButton": "Delete",
        "deleteColumnDialogMessage": "Are you sure you want to delete this column?",
        "deleteColumnDialogTitle": "Delete column?",
        "deleteTaskCancelButton": "Cancel",
        "deleteTaskConfirmButton": "Delete",
        "deleteTaskDialogMessage": "Are you sure you want to delete this card?",
        "deleteTaskDialogTitle": "Delete Card",
        "editCardButton": "Edit card",
        "editCardPaneSaveButton": "Save changes",
        "editCardPaneTitle": "Edit ",
        "editColumnButton": "Edit column",
        "editPaneCancelButton": "Cancel",
        "editPaneDescriptionLabel": "Description:",
        "editPanePriorityLabel": "Priority:",
        "editPaneTitleLabel": "Title:",
        "previewPaneDeleteButton": "Delete",
        "previewPaneEditButton": "Edit",
        "previewPanePriorityLabel": "Priority:",
        "toolbarAddColumnButton": "Add column",
        "toolbarSearchField": "Search"
      },
      "labels": {
        "labelsOptional": "(Optional)"
      },
      "slider": {
        "increment": "Increase",
        "decrement": "Decrease",
        "dragTitle": "Drag"
      },
      "colorGradient": {
        "r": "r",
        "g": "g",
        "b": "b",
        "a": "a",
        "hex": "hex",
        "contrastRatio": "Contrast ratio",
        "colorGradientAALevel": "AA",
        "colorGradientAAALevel": "AAA",
        "colorGradientPass": "Pass",
        "colorGradientFail": "Fail"
      },
      "checkbox": {
        "validation": "Please check this box if you want to proceed!",
        "optionalText": "(Optional)"
      },
      "radioButton": {
        "validation": "Please select option if you want to proceed!"
      },
      "switch": {
        "validation": "Please turn on if you want to proceed!"
      },
      "gantt": {
        "addAbove": "Add Above",
        "addBelow": "Add Below",
        "addChild": "Add Child",
        "addTask": "Add Task",
        "dayViewTitle": "Day",
        "deleteConfirmation": "Are you sure you want to delete this event?",
        "deleteDialogTitle": "Delete Event",
        "editCancel": "Cancel",
        "editSave": "Save",
        "editorDelete": "Delete",
        "editorTaskComplete": "Complete",
        "editorTaskEnd": "End",
        "editorTaskStart": "Start",
        "editorTaskTitle": "Title",
        "editorTitle": "Task",
        "editorValidationEnd": "End time must be after Start time.",
        "editorValidationRequired": "Field is required.",
        "editorValidationStart": "Start time must be be before End time.",
        "filterAfterOperator": "Is after",
        "filterAfterOrEqualOperator": "Is after or equal to",
        "filterBeforeOperator": "Is before",
        "filterBeforeOrEqualOperator": "Is before or equal to",
        "filterBooleanAll": "(All)",
        "filterClearButton": "Clear",
        "filterContainsOperator": "Contains",
        "filterEndsWithOperator": "Ends with",
        "filterEqOperator": "Is equal to",
        "filterGtOperator": "Is greater than",
        "filterGteOperator": "Is greater than or equal to",
        "filterIsEmptyOperator": "Is empty",
        "filterIsFalse": "Is false",
        "filterIsNotEmptyOperator": "Is not empty",
        "filterIsNotNullOperator": "Is not null",
        "filterIsNullOperator": "Is null",
        "filterIsTrue": "Is true",
        "filterLtOperator": "Is less than",
        "filterLteOperator": "Is less than or equal to",
        "filterNotContainsOperator": "Does not contain",
        "filterNotEqOperator": "Is not equal to",
        "filterStartsWithOperator": "Starts with",
        "monthViewTitle": "Month",
        "noRecords": "No records available",
        "weekViewTitle": "Week",
        "yearViewTitle": "Year"
      },
      "multiviewcalendar": {
        "prevView": "Navigate to previous view",
        "nextView": "Navigate to next view"
      },
      "columnMenu": {
        "columnMenuFilterClearButton": "Clear",
        "columnMenuFilterSubmitButton": "Filter",
        "columnMenuFilterTitle": "Filter",
        "columnMenuSortAscending": "Sort Ascending",
        "columnMenuSortDescending": "Sort Descending",
        "columnMenuFilterEqOperator": "Is equal to",
        "columnMenuFilterNotEqOperator": "Is not equal to",
        "columnMenuFilterIsNullOperator": "Is null",
        "columnMenuFilterIsNotNullOperator": "Is not null",
        "columnMenuFilterIsEmptyOperator": "Is empty",
        "columnMenuFilterIsNotEmptyOperator": "Is not empty",
        "columnMenuFilterStartsWithOperator": "Starts with",
        "columnMenuFilterContainsOperator": "Contains",
        "columnMenuFilterNotContainsOperator": "Does not contain",
        "columnMenuFilterEndsWithOperator": "Ends with",
        "columnMenuFilterGteOperator": "Is greater than or equal to",
        "columnMenuFilterGtOperator": "Is greater than",
        "columnMenuFilterLteOperator": "Is less than or equal to",
        "columnMenuFilterLtOperator": "Is less than",
        "columnMenuFilterIsTrue": "Is true",
        "columnMenuFilterAfterOrEqualOperator": "Is after or equal to",
        "columnMenuFilterAfterOperator": "Is after",
        "columnMenuFilterBeforeOperator": "Is before",
        "columnMenuFilterBeforeOrEqualOperator": "Is before or equal to",
        "columnMenuFilterAndLogic": "And",
        "columnMenuFilterOrLogic": "Or"
      },
      "pivotgrid": {
        "fieldMenuReset": "Reset",
        "fieldMenuApply": "Apply",
        "configuratorCancel": "Cancel",
        "configuratorApply": "Apply",
        "configuratorTitle": "Settings",
        "configuratorEditorSearchTitle": "Fields",
        "configuratorEditorSearchPlaceholder": "Search...",
        "configuratorColumnsTitle": "Columns",
        "configuratorRowsTitle": "Rows",
        "configuratorMeasuresTitle": "Values",
        "configuratorButtonLabel": "Change settings",
        "configuratorMissingFields": "Select some fields to begin setup"
      }
};