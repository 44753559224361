import { Button } from "@progress/kendo-react-buttons";
import { useState } from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import * as React from "react";
import {
  Form,
  Field,
  FormElement,
  FieldWrapper} from "@progress/kendo-react-form";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { MultiSelectTree, MultiSelectTreeChangeEvent, MultiSelectTreeExpandEvent, MultiSelectTreeFilterChangeEvent, getMultiSelectTreeValue } from "@progress/kendo-react-dropdowns";
import { FilterDescriptor } from "@progress/kendo-data-query";
import { expandedState, processMultiSelectTreeData } from "../../../../shared/components/MultiSelectTree-Data-Operations";

interface Props {
  closeHandler: () => void;
  isAddFilter: boolean;
  createFilter: (filter: any) => void;
  editableFilter?:any
  allAccounts: any;
  updateFilter: (filterId: string, filteredData: any) => Promise<void>
}

const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "name";

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};

export const AddEditFilterDialog = (props: Props) => {

  const [sharable, setSharable] = useState<boolean>(props.isAddFilter ? true : props.editableFilter.sharable);
 
  const [selectedItems, setSelectedItems] = React.useState<any[]>(props.isAddFilter ? [] : props.allAccounts.filter((item: { id: any; }) => props.editableFilter.siteIds.includes(item.id)));
  const [expanded, setExpanded] = React.useState([props.allAccounts[0][dataItemKey] !== undefined ? props.allAccounts[0][dataItemKey] : null]);
  const [filter, setFilter] = React.useState<FilterDescriptor | null>(null);

  const onChange = (event: MultiSelectTreeChangeEvent) =>
  setSelectedItems(getMultiSelectTreeValue(props.allAccounts, { ...fields, ...event, value: selectedItems }));
  const onExpandChange = React.useCallback(
    (event: MultiSelectTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = React.useMemo(
    () =>
      processMultiSelectTreeData(props.allAccounts, { expanded,  value: selectedItems, filter, ...fields }),
    [expanded, selectedItems, filter]
  );

  const onFilterChange = (event: MultiSelectTreeFilterChangeEvent) =>
    setFilter(event.filter);

  const handleSubmit = async (dataItem: any) => {
    try{
      if(!props.isAddFilter) {
        const castValues = {
          ...dataItem.values,
          sharable: sharable,
          selectedItems: selectedItems,
        };
        await props.updateFilter(props.editableFilter.id, castValues);
        } else {
          const castValues = {
            ...dataItem.values,
            sharable: sharable,
            selectedItems: selectedItems,
          };
          await props.createFilter(castValues);
        }
      } catch (error) {
        console.log(error)
      }
    props.closeHandler();
  }

  return (
    <div className="">
      <Dialog
        title={props.isAddFilter ? "Add Filter" : "Edit Filter"}
        onClose={props.closeHandler}
      >
        <Form
          onSubmitClick={handleSubmit}
          initialValues={
            props.isAddFilter
              ? { name: "", description: "", sharable: true }
              : { name: props.editableFilter.name, description: props.editableFilter.description, sharable: props.editableFilter.sharable,  }
          }
          render={(formRenderProps) => (
            <FormElement style={{ width: 650 }}>
              <fieldset className={"k-form-fieldset"}>
                <FieldWrapper>
                  <div className="k-form-field-wrap">
                  <label className="mr-2">Filter Name</label>
                    <Field
                      name={"name"}
                      component={Input}
                      labelClassName={"k-form-label"}
                      required={true}
                    />
                  </div>
                </FieldWrapper>

                <FieldWrapper>
                  <div className="k-form-field-wrap">
                  <label className="mr-2">Filter Description</label>
                    <Field
                      name={"description"}
                      component={Input}
                      labelClassName={"k-form-label"}
                    />
                  </div>
                </FieldWrapper>

                <FieldWrapper>
                <label className="mr-2">Accounts</label>
                  <div className="k-form-field-wrap">
                    <MultiSelectTree
                      data={treeData}
                      value={selectedItems}
                      onChange={onChange}
                      textField={textField}
                      dataItemKey={dataItemKey}
                      checkField={checkField}
                      checkIndeterminateField={checkIndeterminateField}
                      expandField={expandField}
                      subItemsField={subItemsField}
                      onExpandChange={onExpandChange}
                      filterable={true}
                      onFilterChange={onFilterChange}
                      required={true}
                      style={{maxHeight:'60px' , overflow:'auto',}}
                    />
                  </div>
                </FieldWrapper>

                <FieldWrapper>
                <label className="mr-2">Share with others:</label>
                  <div className="k-form-field-wrap">
                    <Field
                      name={"sharable"}
                      component={Switch}
                      trackRounded={"medium"}
                      thumbRounded={"medium"}
                      defaultChecked={sharable}
                      onChange={(event:any) => setSharable(event.value)}
                    />
                  </div>
                </FieldWrapper>

              </fieldset>
              <span className={"k-form-separator"} />
              <div className="p-2" style={{ textAlign: "right" }}>
                <Button
                  type={"submit"}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  style={{
                    background: "#35AC00",
                    color: "white",
                    width: " 62px",
                    marginRight: "5px",
                  }}

                >
                  Save
                </Button>
                <Button onClick={props.closeHandler}>
                  Cancel
                </Button>
              </div>
            </FormElement>
          )}
        />
      

      </Dialog>
    </div>
  );
};  
