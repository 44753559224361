import { Button } from "@progress/kendo-react-buttons";
import * as svgIcons from '@progress/kendo-svg-icons';
import { AddEditFilterDialog } from "./AddEditFilterDialog";
import accountsApiService from "../../utilities/ExplorerApiService";
import { ReactQueryKeys } from "../../../../constants/ClientRoutingConstants";
import { useQuery, useQueryClient } from "react-query";
import ErrorCard from "../../../shared/components/ErrorCard";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import DeleteDialog from "../../../shared/components/DeleteDialog";

interface Props {
  isAddFilter: boolean;
  updateIsAddFilter: (isAddFilter: boolean) => void;
  filterToUpdate: any | null;
  setFilterToUpdate: (filter: any | null) => void;
  selectedItem:  { id: string; siteId: string ; type: string, parentId: string, name:string }[];
  filterToDelete: any | null;
  setFilterToDelete: (filter: any | null) => void;
}

export const FilterList = (props: Props) => {
  const client = useQueryClient();

  const createFilter= async (filter:any) => {
    const result = await accountsApiService.createFilter(filter);
    client.refetchQueries(ReactQueryKeys.AllExplorerFiltersQuery);
  };

  const updateFilter = async (filterId: string, filteredData: any) => {
    let response = await accountsApiService.updateFilter(filteredData, filterId);
    client.invalidateQueries(ReactQueryKeys.AllExplorerFiltersQuery);
  };

  const deleteAlert = async (alertrId: string) => {
    try {
      let response = await accountsApiService.deleteFilter(alertrId);

      //If delete is successful, refetch the updated list of alerts and show success message
      client.refetchQueries(ReactQueryKeys.AllExplorerFiltersQuery);

      // toast({
      //   status: "success",
      //   description: `${localizationService.toLanguageString('custom.alertSuccessfullydeleted', enMessages.custom.alertSuccessfullydeleted)}`,
      // });
    } catch (e) {
      console.log(e)
      // toast({
      //   status: "error",
      //   description: `${localizationService.toLanguageString('custom.errorDeletingAlert', enMessages.custom.errorDeletingAlert)}`,
      // });
    }
  };

  const filterQuery = useQuery<any>(["FilterQueryId", props.selectedItem[0]?.id],
    async () => {
      return accountsApiService.getFilter(props.selectedItem[0]?.id);
    },
    {enabled: props.selectedItem.length === 1 && props.selectedItem[0]?.type === 'Filter'}
  );

  const accountsQuery = useQuery<any>([ReactQueryKeys.AllAccountsQuery],
    async () => {
      return accountsApiService.getAllAccounts();
    },
  );

  const closeHandler = () => {
    props.updateIsAddFilter(false);
    props.setFilterToUpdate(null);
  };

  

  const onDelete = () => {
    if (props.filterToDelete != null) {
      deleteAlert(props.filterToDelete[0]?.id);
    }

    props.setFilterToDelete(null);
  };
  

  if(filterQuery.isLoading || accountsQuery.isLoading) 
    return <LoadingSpinner />
  else if(filterQuery.isError || accountsQuery.isError){
    return <ErrorCard />
  } else {
    return (
    <div className="">
      <div className="flex gap-1 justify-end" style={{background: "#FAFAFA", borderColor: "#EBEBEB", borderWidth: "1px 1px 0 1px",}}>
        <Button svgIcon={svgIcons.plusIcon} themeColor={"primary"} onClick={() => props.updateIsAddFilter(!props.isAddFilter)}/>
        <Button 
          svgIcon={svgIcons.pencilIcon} 
          themeColor={"primary"} 
          disabled={!(props.selectedItem.length === 1 && props.selectedItem[0]?.type === 'Filter')} 
          onClick={()=> props.setFilterToUpdate(props.selectedItem)}/>
        <Button 
          svgIcon={svgIcons.trashIcon} 
          disabled={!(props.selectedItem.length === 1 && props.selectedItem[0]?.type === 'Filter')} 
          onClick={() => props.setFilterToDelete(props.selectedItem)}
          themeColor={"primary"} />
      </div>

      {(props.isAddFilter || props.filterToUpdate !== null) && (
       <AddEditFilterDialog 
        closeHandler={closeHandler}
        isAddFilter={props.isAddFilter}
        createFilter={createFilter}
        editableFilter={filterQuery.data}
        allAccounts={accountsQuery.data!}
        updateFilter={updateFilter}
       />
      )}

      {props.filterToDelete != null && (
        <DeleteDialog
          title={"Delete Filter"}
          deletingItemLabel={props.filterToDelete[0]?.name}
          itemCount={0}
          open={props.filterToDelete != null}
          onClose={() => props.setFilterToDelete(null)}
          deleteCallback={onDelete}
          children={undefined}
        />
      )}
  </div>
  );
}}
