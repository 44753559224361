import { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

interface Props {}

export default function ErrorCard(props: Props): ReactElement {
  const localizationService = useLocalization();
  return (
        <div className="px-4 py-5 bg-red-200 shadow rounded-md">
          {/* Form */}
          <div className="bg-red-200 p-6">
            <div className="flex flex-wrap justify-center md:flex-nowrap md:justify-start">
            <SvgIcon icon={svgIcons.warningTriangleIcon} />
              <div className="ml-4 font-bold w-100">
              An Error has occured.
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="justify-start bg-red-200 text-left px-6">
            <p className="mt-1 text-sm">
            The server returned an error stating that you do not have the required permissions to access the resources for this page.
            </p>
          </div>
        </div>
  );
}
