import { Grid, GridColumn, GridDataStateChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import moment from 'moment';
import { DataResult, SortDescriptor, State, process } from "@progress/kendo-data-query";
import { useRef, useState } from "react";
import { CustomColumnMenu } from "../../../shared/utilities/customColumnMenu";
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { EndpointStatus } from "../../../../models/endpoints/EndpointSatus";

interface Props {
  endpointStatus: EndpointStatus[];
}

export const EndpointStatusTable = (props: Props) => {

  const endpointStatus = props.endpointStatus

  const LastFlowCell = (props: any) => {
    const lastFlowEvent = moment(props.dataItem.lastFlowEvent);
    const today = moment();

    const differenceInHours = today.diff(lastFlowEvent, 'hours');
    const trafficLight = differenceInHours > 72 ? "#ff5034" : "#1ab394"
    
    const lastFlow = lastFlowEvent.year() === 1970 ? "No Data" : lastFlowEvent.fromNow();

    return (
      <td>
        <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
        <span>{lastFlow}</span>
      </td>
    );
  }

  const LastDiagnosticCell = (props: any) => {
    const lastDiagnosticEvent = moment(props.dataItem.lastDiagnosticEvent);
    const today = moment();

    const differenceInHours = today.diff(lastDiagnosticEvent, 'hours');
    const trafficLight = differenceInHours > 12 ? "#ff5034" : "#1ab394"

    const lastDiagnostic = lastDiagnosticEvent.year() === 1970 ? "No Data" : lastDiagnosticEvent.fromNow();

    return (
      <td>
        <SvgIcon icon={svgIcons.circleIcon}size="xxlarge" style={{ color: trafficLight}}/>
        <span>{lastDiagnostic}</span>
      </td>
    );
  }

  const LastHeartBeatCell = (props: any) => {
    const lastHeartBeatEvent = moment(props.dataItem.lastHeartbeatEvent);
    const today = moment();

    const differenceInHours = today.diff(lastHeartBeatEvent, 'hours');
    const trafficLight = differenceInHours > 12 ? "#ff5034" : "#1ab394"

    const lastHeartBeat = lastHeartBeatEvent.year() === 1970 ? "No Data" : lastHeartBeatEvent.fromNow();

    return (
      <td>
          <SvgIcon icon={svgIcons.circleIcon} size="xxlarge" style={{ color: trafficLight}}/>
        <span>{lastHeartBeat}</span>
      </td>
    );
  }

  const initialSort: Array<SortDescriptor> = [
    { field: "lastFlowEvent", dir: "asc" },
    { field: "lastDiagnosticEvent", dir: "asc" },
    { field: "lastHeartbeatEvent", dir: "asc" },
  ];
  const [sort] = useState(initialSort);

  const createDataState = (dataState: State) => {
    return {
      result: process(endpointStatus.slice(0), dataState),
      dataState: dataState,
    };
  };

  let initialState = createDataState({
    take: 30,
    skip: 0,
    sort: sort,
  });

  const [result, setResult] = useState<DataResult>(initialState.result);
  const [dataState, setDataState] = useState<State>(initialState.dataState);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  const columns = [
    {
      title: `Account`,
      field: "accountName",
      show: true,
      filter: "text",
      filterable: true,
    },
    {
      title: `Location`,
      field: "locationName",
      show: true,
      filter: "text",
      filterable: true,

    }, 
    {
      title: `Asset`,
      field: "assetName",
      show: true,
      filter: "text",
      filterable: true,
    },
    {
      title: `Endpoint`,
      field: "endpointName",
      show: true,
      filter: "text",
      filterable: true,
    },
    {
      title: `Serial Number`,
      field: "serialNumber",
      show: true,
      filter: "text",
      filterable: true,
    },
    {
      title: `Last Flow`,
      field: "lastFlowEvent",
      show: true,
      filter: "text",
      filterable: true,
      cell: LastFlowCell,
    },
    {
      title: `Last Diagnostic`,
      field: "lastDiagnosticEvent",
      show: true,
      filter: "text",
      filterable: true,
      cell: LastDiagnosticCell,
    },
     {
      title: `Last Heartbeat`,
      field: "lastHeartbeatEvent",
      show: true,
      filter: "text",
      filterable: true,
      cell: LastHeartBeatCell,
     }
  ]

  const [stateColumns, setStateColumns] = useState<any>(columns);
  const onColumnsSubmit = (columnsState: any[]) => {
    setStateColumns(columnsState);
  };

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const getExcelExportData = () => {
    return process(endpointStatus, {filter: dataState.filter, sort: dataState.sort}).data;
  };
console.log(endpointStatus)
  return (
    <div className="" >
      <ExcelExport data={getExcelExportData()} ref={_export} collapsible={true} fileName="Endpoint Status">
        <ExcelExportColumn field="accountName" title="Account" />
        <ExcelExportColumn field="locationName" title="Location" />
        <ExcelExportColumn field="assetName" title="Asset" />
        <ExcelExportColumn field="endpointName" title="Endpoint"/>
        <ExcelExportColumn field="serialNumber" title="Serial Number"/>
        <ExcelExportColumn field="lastFlowEventsExcel" title="Last Flow (UTC)" width={150}/>
        <ExcelExportColumn field="lastDiagnosticsEventExcel" title="Last Diagnostics (UTC)" width={150}/>
        <ExcelExportColumn field="lastHeartbeatEventExcel" title="Last Heartbeat (UTC)" width={150}/>
      </ExcelExport>
      <Grid 
        key={'EndpointStatusTable'}
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageSize={30}
        scrollable={"virtual"} 
        rowHeight={50}
        style={{height: '625px'}}
      >
         <GridToolbar >
          <div style={{width:'100%', display:'flex', justifyContent:'space-between'}}>
            <div className="gb-h3 pl-1">Endpoint Status</div>
            <Button
              svgIcon={svgIcons.downloadIcon}
              onClick={excelExport}
            >
            </Button>
          </div>
        </GridToolbar>
        {stateColumns.map(
        (column: any, idx: any) => 
          (column.show && !column.isHidden) && (
            <GridColumn
              key={idx}
              field={column.field}
              title={column.title}
              filter={column.filter}
              cell={column.cell}
              width={column.width}
              columnMenu={(props) => (
                <CustomColumnMenu
                  {...props}
                  columns={stateColumns}
                  onColumnsSubmit={onColumnsSubmit}
                  data={endpointStatus}
                  filterable={column.filterable}
                />
              )}
            />
          )
        )}
      </Grid>
    </div>
  );
};
