import { ReactElement } from "react";
import { useLocalization } from '@progress/kendo-react-intl';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';

export default function NoDataPage(): ReactElement {
  const localizationService = useLocalization();


  return (
    <div className="gb-text-normal  text-lg text-center w-full"
        style={{ borderRadius: '3px', padding: '40px 25px',margin: '30px auto', height: '617px'}}>
        <div className="text-center mb-5">
          <SvgIcon icon={svgIcons.warningTriangleIcon} size="xxxlarge"/>
        </div>
        <div>
        
            <div>{`No Data Found`}</div>
            <br></br>
            <div>{`Please select an item.`}</div>
        </div>
    </div> 
  )}
