import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useState } from "react";
import { EndpointStatusPage } from "./EndpointsStatus/EndpointStatusPage";
import NoDataPage from "./NoDataPage";
import { OverviewSelectedPage } from "./Overview/OverviewSelectedPage";
import { FilterHealthTabPage } from "./Health/FilterHealthTabPage";
import { ReactQueryKeys } from "../../../constants/ClientRoutingConstants";
import accountsApiService from "../utilities/ExplorerApiService";
import { useQuery } from "react-query";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import ErrorCard from "../../shared/components/ErrorCard";
import { AccountSettings } from "../../../models/accounts/AccountSettings";


interface Props {
  selectedItems:any;
  selectedFilter: any;
}

export const ExplorerTabsPage = (props:Props) => {

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const accountSettingsQuery = useQuery<AccountSettings>(
    [ReactQueryKeys.AllAccountSettingsQuery], 
    async () => {
      return accountsApiService.getAccountSettings(props.selectedItems[0].siteId);
    },
    {
      enabled: props.selectedItems.length === 1 && props.selectedItems[0].type === 'Account'
    }
  );

  function PageSelectorStatus() {
    return (props.selectedItems.length === 0) ? (
      <NoDataPage />
    ) : (
      <EndpointStatusPage selectedItems={props.selectedItems} />
    );
  }

  function PageSelectorOverview() {
    return (props.selectedItems.length === 0) ? (
      <NoDataPage />
    ) : (
      <OverviewSelectedPage selectedItem={props.selectedItems} selectedFilter={props.selectedFilter}/>
    );
  }

  if(accountSettingsQuery.isLoading || accountSettingsQuery.isFetching) {
    return <LoadingSpinner />
  }
  if(accountSettingsQuery.isError) {
    return <ErrorCard />
  }
  return (
    <div className="">
      <TabStrip
        selected={selectedTab}
        onSelect={(e) => setSelectedTab(e.selected)}
        style={{ height: "100%" }}
      >
          <TabStripTab title="Overview">
          {PageSelectorOverview()}
        </TabStripTab>
        <TabStripTab title="Endpoints Status">
          {PageSelectorStatus()}
        </TabStripTab>
        {(props.selectedItems.length > 0) &&
        <TabStripTab title='Health'>
          <FilterHealthTabPage selectedItems={props.selectedItems} />
        </TabStripTab>}
      </TabStrip>
    </div>
  );
};
