export const locales = [
    {
        locale: 'English',
        localeId: 'en-AU' || 'en-GB',
        abbreviation: 'AU'
    },
    {
        locale: 'Spanish',
        localeId: 'es',
        abbreviation: 'ES'
    },
    {
        locale: 'Portuguese',
        localeId: 'pt-BR',
        abbreviation: 'BR'
    },
    
];