import { ReactElement } from "react";
import LogoImage from "../../../assets/logo-blue.png";

export default function LoadingSpinner(): ReactElement {
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <img src={LogoImage} className="h-20 w-20 relative" alt=""/>
        <div className="absolute animate-spin rounded-full h-32 w-32 border-b-2 border-primary-400"></div>
        <div className=" absolute mt-44">Loading...</div>
      </div>
    </>
  );
}
