import { useQuery } from "react-query";
import { ReactQueryKeys } from "../../../../constants/ClientRoutingConstants";
import accountsApiService from "../../utilities/ExplorerApiService";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import ErrorCard from "../../../shared/components/ErrorCard";
import { EndpointStatusTable } from "../../components/EndpointsStatus/EndpointStatusTable";
import { EndpointStatus } from "../../../../models/endpoints/EndpointSatus";
import { useEffect, useMemo, useState } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import moment from "moment";

interface Props {
  selectedItems: { id: string; siteId: string | undefined; type: string | undefined, parentId: string | undefined }[];
}

export const EndpointStatusPage = (props: Props) => {

  const [isReportByException, setIsReportByException] = useState<boolean>(true);

  const selectedEndpointStatusQuery = useQuery<EndpointStatus[]>(ReactQueryKeys.AllSelectedEndpoinstStatusQuery, async () => {
    return accountsApiService.getSelectedEndpointsStatus(props.selectedItems, isReportByException);
  }, );

  const endpoints = useMemo(() => {
    return selectedEndpointStatusQuery.data?.map((endpoint) => {

      return {
        ...endpoint,
        lastFlowEventsExcel: moment(endpoint.lastFlowEvent).year() === 1970 ? '-' : moment.utc(endpoint.lastFlowEvent).format('DD/MM/YYYY hh:mm:ss'),
        lastDiagnosticsEventExcel: moment(endpoint.lastDiagnosticEvent).year() === 1970 ? '-' :  moment.utc(endpoint.lastDiagnosticEvent).format('DD/MM/YYYY hh:mm:ss'),
        lastHeartbeatEventExcel: moment(endpoint.lastHeartbeatEvent).year() === 1970 ? '-' :  moment.utc(endpoint.lastHeartbeatEvent).format('DD/MM/YYYY hh:mm:ss'),
       
      }
    }) ?? [];
  }, [selectedEndpointStatusQuery.data]);


  useEffect(() => {
    selectedEndpointStatusQuery.refetch()
  }, [props.selectedItems, isReportByException]);

  if(selectedEndpointStatusQuery.isFetching || selectedEndpointStatusQuery.isLoading) {
    return <LoadingSpinner />
  } else if(selectedEndpointStatusQuery.isError) {
    return <ErrorCard/>
  } else {
  return (
    <div>
      <div className="flex gap-2 items-center mb-5">
        <div>Report By Exception</div>
        <Switch trackRounded={"medium"} thumbRounded={"medium"} checked={isReportByException} onChange={(e) => {setIsReportByException(e.target.value)}}/>
      </div>
      <EndpointStatusTable endpointStatus={endpoints}/>
    </div>
  );
}}
