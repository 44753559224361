import { useEffect, useState } from "react";
import authorizeService from "../../identity/utilities/AuthorizeService";
import LoadingSpinner from "../../shared/components/LoadingSpinner";

interface Props {}

export const AlertJobsPage = (props: Props) => {
  const [token, setToken] = useState(''); 

  useEffect(() => {
    async function getToken() {
      const user = await authorizeService.getUser();
      setToken(user.access_token);
    }

    getToken();
  }, []);

  const urlBase = process.env.REACT_APP_HANGFIRE_ALERTS_URL

  if (!token) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className="">
        <iframe title="AlertJobs" src={`${urlBase}?jwt=${token}`} width={"100%"} height={700} ></iframe>
      </div>
    );
}}


