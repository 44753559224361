//Within this page we expose non secret constants for backend api routing.

export const BaseUrl = process.env.REACT_APP_BACKEND_URL

export const IdentityRoutes = {
    Login : "/account/login",
    LoginQueryParameters: {
        ReturnUrl: "returnUrl"
    },
    ChangeOrganisation : "/organisation/change"
}

export const ApiRoutes = {
    //Endpoint Status
    EndpointStatus: "admin/v1/endpoints/status",

    //Explorer Filters
    ExplorerFilters: "admin/v1/filters",
    ExplorerAccountHealth: "admin/v1/account-health",

    //Accounts
    Accounts: "admin/v1/accounts/filters",
    AccountSettings: "admin/v1/accounts/settings",

}